// Sample events calendar build, explained and detailed over at
// https://justacoding.blog/react-calendar-component-example-with-events/

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import ButtonMobileComponent from 'components/button-mobile'
import DialogComponent from 'components/dialog'
import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import { useState } from 'react'
import { getTimeNumber, getTimeString } from 'utils/constants'
import { CalendarEventProps, CalendarProps, CalendarState } from 'utils/types'
import './index.css'
dayjs.extend(customParseFormat)

// Interfaces
type CalendarNavigationProps = {
  date: Date
  setDate: (v: Date) => void
}

type CalendarEventFormProps = {
  event: CalendarEventProps
  onChange: (v?: CalendarProps) => Promise<void>
}

type CalendarGridProps = {
  date: Date
  events: CalendarEventProps[]
  setShowEvent: (v: CalendarEventProps | null) => void
}

// Calendar Components
// Top bar, contains the month/year combo as well as back/forward links
const Navigation = ({ date, setDate }: CalendarNavigationProps) => {
  const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  return (
    <div className='navigation'>
      <div
        className='back'
        onClick={() => {
          const newDate = new Date(date)
          newDate.setMonth(newDate.getMonth() - 1)
          setDate(newDate)
        }}
      >
        <ChevronLeftOutlinedIcon style={{ fontSize: 20 }} />
        {MONTHS[date.getMonth() === 0 ? 11 : date.getMonth() - 1]}
      </div>

      <div className='monthAndYear'>
        {MONTHS[date.getMonth()]} {date.getFullYear()}
      </div>

      <div
        className='forward'
        onClick={() => {
          const newDate = new Date(date)
          newDate.setMonth(newDate.getMonth() + 1)
          setDate(newDate)
        }}
      >
        {MONTHS[date.getMonth() === 11 ? 0 : date.getMonth() + 1]}
        <ChevronRightOutlinedIcon style={{ fontSize: 20 }} />
      </div>
    </div>
  )
}

const DayLabels = (day: string, idx: number) => (
  <div key={idx} className='w-[20%] flex items-center justify-center text-xs p-0'>
    {day}
  </div>
)

// Form to add new events or edit existing events
// In a real implementation, we'd have some frontend
// validation and also the equivalent in our
// backend service...
const EventForm = ({ event, onChange }: CalendarEventFormProps) => {
  const { from } = event
  const [selectedEvent, setSelectedEvent] = useState<CalendarEventProps>({ from })
  const [selectedSchedule, setSelectedSchedule] = useState<CalendarState | undefined>(CalendarState.ENABLE)

  // handlers
  const handleClose = () => onChange().catch((e: Error) => console.error(e))
  const handleSave = () => {
    if (selectedSchedule)
      onChange({ ...selectedEvent, userAction: selectedSchedule }).catch((e: Error) => console.error(e))
  }

  // elements
  const body: JSX.Element = (
    <>
      <label className='text-gray-600 text-sm font-medium leading-[1.42857]'>Your Afternoon Schedule</label>
      <div className='flex flex-wrap mt-4'>
        <span
          onClick={() => setSelectedSchedule(CalendarState.DISABLE)}
          className={`inline-block text-sm mb-3 mx-2 rounded-xl px-2 py-1 cursor-pointer ${
            selectedSchedule === CalendarState.DISABLE
              ? 'bg-custom-yellow text-gray-800'
              : 'bg-yellow-100 text-gray-400'
          }`}
        >
          {`Don't need carpool`}
        </span>
        <span
          onClick={() => setSelectedSchedule(CalendarState.ENABLE)}
          className={`inline-block text-sm mb-3 mx-2 rounded-xl px-2 py-1 cursor-pointer ${
            selectedSchedule === CalendarState.ENABLE ? 'bg-custom-yellow text-gray-800' : 'bg-yellow-100 text-gray-400'
          }`}
        >
          Set time
        </span>
      </div>
      {selectedSchedule === CalendarState.ENABLE && (
        <div className='flex flex-col gap-2 mt-2'>
          <label className='text-gray-600 text-sm font-medium leading-[1.42857]'>School pickup time</label>
          <TimePicker
            value={dayjs(selectedEvent.from)}
            onChange={(d: Dayjs | null) => {
              if (d) setSelectedEvent({ ...selectedEvent, from: d.toDate() })
            }}
          />
        </div>
      )}
    </>
  )
  const action: JSX.Element = (
    <>
      <div className='flex flex-grow items-center w-full'>
        <ButtonMobileComponent text='Save' width='w-full' onClick={handleSave} />
      </div>
    </>
  )

  return <DialogComponent title={from.toDateString()} body={body} action={action} onCancel={handleClose} />
}

// The grid of days, renders a month's worth of days and
// also populates the events on the relevant dates
const Grid = ({ date, events, setShowEvent }: CalendarGridProps) => {
  const toStartOfDay = (date: Date): Date => {
    const newDate = new Date(date)
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)
    return newDate
  }

  const findEventsForDate = (events: CalendarEventProps[], date: Date): CalendarEventProps[] => {
    return events.filter((event: CalendarEventProps) => date.getTime() === toStartOfDay(event.from).getTime())
  }

  // Finds the closest Monday relative to the first day of
  // the target month/year combination
  // Then increment upon this day until we have a full set
  // of date objects to work with
  const startingDate = new Date(date.getFullYear(), date.getMonth(), 1)
  startingDate.setDate(startingDate.getDate() - (startingDate.getDay() - 1))
  const monthsEvents: { date: Date; event: CalendarEventProps }[] = []
  const WEEKS_COUNT: number = 6,
    DAYS_COUNT: number = 7
  for (let i = 0; i < WEEKS_COUNT * DAYS_COUNT; i++) {
    const date = new Date(startingDate)
    const isWeekday = (): boolean => date.getDay() !== 0 && date.getDay() !== 6
    // skip saturday and sunday weekend days - use Mon-Fri 5 day labels with a width of 20% each
    if (isWeekday()) {
      monthsEvents.push({
        date,
        event: findEventsForDate(events, date)[0],
      })
    }
    startingDate.setDate(startingDate.getDate() + 1)
  }
  const currentDate: Date = toStartOfDay(new Date())

  return (
    <>
      {monthsEvents.map((v, i) => {
        const isPast = v.date.getTime() < currentDate.getTime()
        const date: number = v.date.getDate()
        const { from, eventState } = v.event ?? {}
        if (isPast) {
          const color: string = '#ddd'
          return (
            <div key={i} className={`w-[20%] h-[60px] p-0 space-y-1 flex flex-col items-center justify-center text-xs`}>
              <div className={`w-[50%] h-[2px]`} style={{ background: color }} />
              <div style={{ color: 'gray' }}>{date}</div>
            </div>
          )
        } else {
          const color: string =
            eventState === CalendarState.ENABLE ? 'orange' : eventState === CalendarState.DISABLE ? 'red' : 'green'
          const time: string =
            eventState === CalendarState.ENABLE
              ? getTimeString(getTimeNumber(from))
              : eventState === CalendarState.DISABLE
                ? 'X'
                : ''
          const onClick = () => setShowEvent({ from, eventState })
          return (
            <div key={i} className={`w-[20%] h-[60px] p-0 space-y-1 flex flex-col items-center justify-center text-xs`}>
              <div className={`w-[50%] h-[2px]`} style={{ background: color, cursor: 'pointer' }} onClick={onClick} />
              <div style={{ color: 'black', cursor: 'pointer' }} onClick={onClick}>
                {date}
              </div>
              <div className={`h-[1px]`} style={{ color, cursor: 'pointer' }} onClick={onClick}>
                {time}
              </div>
            </div>
          )
        }
      })}
    </>
  )
}

export const CalendarComponent = (props: any) => {
  const events: CalendarEventProps[] = props.events

  const [date, setDate] = useState<Date>(props?.date ?? new Date())
  const [showEvent, setShowEvent] = useState<CalendarEventProps | null>()

  const onEvent = async (event?: CalendarEventProps): Promise<void> => {
    if (event) {
      setShowEvent(null)
      await props.onChange(event)
    } else setShowEvent(null)
  }

  return (
    <div className='calendar'>
      <Navigation date={date} setDate={setDate} />

      {['Mo', 'Tu', 'We', 'Th', 'Fr'].map(DayLabels)}

      <Grid date={date} events={events} setShowEvent={setShowEvent} />

      {showEvent && <EventForm event={showEvent} onChange={onEvent} />}
    </div>
  )
}
