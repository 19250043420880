import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Box, Dialog, DialogTitle } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { BillingDisabledComponent, BillingPaymentComponent } from 'components/billing-settings'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import InputComponent from 'components/input'
import ModalConfirmComponent from 'components/modal-confirm'
import PaperComponent from 'components/paper'
import SwitchComponent from 'components/switch'
import CircularProgressWithLabel from 'components/table/CircularProgressLabel'
import ToastComponent from 'components/toast'
import React, { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import {
  isBillingDisabledForFamily,
  parseJwt,
  passwordTooltip,
  reenterPasswordTooltip,
  setItem,
  userSubscriptionLabel,
} from 'utils/constants'
import { MessageProps, StudentProps, SubscriptionProps, UserProps } from 'utils/types'
import { deleteUser, getUser, signInUser, updateUserPassword } from 'utils/users'
import { isMatchingPassword, isValidPassword } from 'utils/validations'
import './index.css'

interface Props {
  tokens: any
  students: StudentProps[]
}

function Index(props: Props): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: Tokens, Students, User Relation, and Callback
  const { tokens, students } = props ?? {}
  const { AccessToken: accessToken, IdToken } = tokens
  const phoneNumber: string = parseJwt(IdToken).phone_number

  // states
  // Change Password States
  const [password, setPassword] = useState({ current: '', new: '', reenteredNew: '' })
  // Billing States
  // Two sources of truth: School subscription (MASTER) & Family subscription (SLAVE)
  // 1. Easy Scenario...
  //    Family registered for 1 school => school has subscribed => No family subscriptions required => No Billing Settings
  // 2. Hard Scenario...
  //    Family registered for 3 school => all schools have subscribed => No family subscriptions required => No Billing Settings
  // 3. Crazy Scenario...
  //    Family registered for 3 school => 2 schools have NOT subscribed => Family subscriptions required => Billing Settings Shown
  //    We will charge for 2 schools (total registered schools minus schools that have subscribed)
  const isShowBillingSettings: boolean = false // students?.every((v: any) => v?.isSchoolSubscription) ? false : true
  const [isUserSubscription, setIsUserSubscription] = useState<boolean>(
    students?.some((v: any) => v?.isStudentSubscription) ? true : false,
  )
  const [userSubscription, setUserSubscription] = useState<SubscriptionProps>()
  // User Settings States
  const [delOpen, setDelOpen] = useState(false)
  // Common States
  const [msg, setMsg] = useState<MessageProps>()
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [isLoading, setIsLoading] = React.useState<string>('')

  // hooks
  useEffect(() => {
    const loadUserData = async () => {
      const { data, msg, error } = await getUser(phoneNumber)
      if (error) setMsg(msg)
      else {
        const u: UserProps = data?.user as UserProps
        setIsUserSubscription(u?.userSubscription?.billingCycle ? true : false)
        setUserSubscription(u?.userSubscription)
      }
      setIsFirstLoad(false)
    }
    loadUserData()
  }, [phoneNumber])

  // handlers
  // Function to close delete confirmation dialog
  const handleDelClose = () => {
    setDelOpen(false)
  }
  // Function to open dialog for delete confirmation
  const handleDelClick = () => {
    setDelOpen(true)
  }
  // Function to handle delete submit
  const handleDelSubmit = async () => {
    setDelOpen(false)
    setIsLoading('Deleting Family Users Account')
    const { msg, error } = await deleteUser(phoneNumber)
    setIsLoading('')
    if (error) setMsg(msg)
    else {
      setItem('tokens', null)
      navigate('/')
    }
  }

  if (isFirstLoad) return <LinearProgress />

  const isPasswordValid: boolean =
    isValidPassword(password.current, true) &&
    isValidPassword(password.new, true) &&
    isMatchingPassword(password.reenteredNew, password.new, true)

  return (
    <CenterComponent marginTop={0}>
      {/***************** Change Password *****************/}
      <PaperComponent title={'Change Password'}>
        <Box display='flex' flexDirection='column' justifyContent='center' paddingTop={2} gap={2}>
          <InputComponent
            type='password'
            data_testid='current-password-id'
            label='Current Password'
            isError={!isValidPassword(password.current)}
            onChange={(v: string) => setPassword({ ...password, current: v })}
          />
          <InputComponent
            type='password'
            data_testid='new-password-id'
            label='New Password'
            isError={!isValidPassword(password.new)}
            helperText={!isValidPassword(password.new) ? passwordTooltip() : ''}
            onChange={(v: string) => setPassword({ ...password, new: v })}
          />
          <InputComponent
            type='password'
            data_testid='reenter-password-id'
            label='Re-enter New Password'
            isError={!isMatchingPassword(password.reenteredNew, password.new)}
            helperText={!isMatchingPassword(password.reenteredNew, password.new) ? reenterPasswordTooltip() : ''}
            onChange={(v: string) => setPassword({ ...password, reenteredNew: v })}
          />
          <ButtonComponent
            text='Save'
            disable={!isPasswordValid}
            endIcon={<ArrowForwardIcon />}
            onClick={async () => {
              setIsLoading('Updating user password...')
              const { msg, error } = await updateUserPassword(phoneNumber, accessToken, password.current, password.new)
              if (error) setMsg(msg)
              else {
                const { msg, error } = await signInUser(phoneNumber, password.new)
                if (error) setMsg(msg)
                else {
                  setPassword({ current: '', new: '', reenteredNew: '' })
                  setMsg({ style: 'success', text: 'Password updated successfully.' })
                }
              }
              setIsLoading('')
            }}
          />
        </Box>
      </PaperComponent>

      {/***************** Subscription *****************/}
      {isShowBillingSettings && (
        <PaperComponent title={`Subscription`}>
          <SwitchComponent isChecked={isUserSubscription} onChange={() => setIsUserSubscription(!isUserSubscription)}>
            {userSubscriptionLabel(isUserSubscription)}
          </SwitchComponent>
          {isUserSubscription && (
            <>
              {isBillingDisabledForFamily ? (
                <BillingDisabledComponent
                  body={`Online subscription management is currently in development. Please contact our support team at support@carpool.school to activate your free subscription.`}
                  onSubmit={(v: SubscriptionProps) => setUserSubscription({ ...userSubscription, ...v })}
                />
              ) : (
                <BillingPaymentComponent
                  name={phoneNumber}
                  paymentMethod={userSubscription?.paymentMethod}
                  onSubmit={(v: string) =>
                    setUserSubscription({ ...userSubscription, paymentMethodId: v, billingCycle: 'MONTHLY_ROLLING' })
                  }
                />
              )}
            </>
          )}
        </PaperComponent>
      )}

      {/***************** Delete User *****************/}
      <ButtonComponent
        startIcon={<DeleteOutlinedIcon />}
        onClick={handleDelClick}
        variant='outlined'
        color='error'
        text='Delete Account'
      />
      <ModalConfirmComponent
        isShow={delOpen}
        title={students.length ? `Account Deletion Blocked` : `Delete Account?`}
        body={
          students.length
            ? `You can’t delete your account while registered with schools. Please remove school affiliations to delete your account. For help, contact support@carpool.school.`
            : `You will lose access to this account. This action cannot be undone.`
        }
        actionIcon={students.length ? 'redBlockIcon' : 'redInfoIcon'}
        onClose={students.length ? handleDelClose : undefined}
        onActionCancel={students.length ? undefined : handleDelClose}
        onActionSubmit={students.length ? undefined : handleDelSubmit}
      />

      {/***************** Display progress *****************/}
      {isLoading && (
        <Dialog open={true}>
          <DialogTitle>{isLoading}</DialogTitle>
          <CircularProgressWithLabel />
        </Dialog>
      )}

      {/***************** Display messages *****************/}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </CenterComponent>
  )
}

export default Index
