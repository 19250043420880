import AddIcon from '@mui/icons-material/Add'
import { Box, Dialog, DialogTitle, Radio } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import ModalConfirmRemoveComponent from 'components/modal-confirm-remove'
import PaperComponent from 'components/paper'
import SelectComponent from 'components/select'
import CircularProgressWithLabel from 'components/table/CircularProgressLabel'
import TextComponent from 'components/text'
import ToastComponent from 'components/toast'
import React, { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { titleCaseSchoolNameForUI } from 'utils/constants'
import { deleteStudent, updateStudentSettings } from 'utils/students'
import { MessageProps, StudentProps } from 'utils/types'
import './index.css'

interface Props {
  students: StudentProps[]
  processQuery: (id: string) => string
  onUpdate: (cb?: () => void) => void
  onSelect: (ssi: string, cb?: () => void) => void
  onRemove: (ssi: string, cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const theme = useTheme()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate: NavigateFunction = useNavigate()

  // Inputs: Students, Selected Student Id and Callback
  const { students, processQuery, onUpdate, onSelect, onRemove } = props ?? {}

  // We call this component without student id query search parameters when a school is removed
  const studentIdFromQuery = searchParams.get('id') ?? ''
  useEffect(() => {
    setSearchParams({ id: processQuery(studentIdFromQuery) })
  }, [studentIdFromQuery, processQuery, setSearchParams])

  // states
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = React.useState<string>('')

  // handlers
  // Handle change school
  const handleSelectSchool = async (studentId: string) => {
    await onSelect(studentId, () => navigate(`/change?id=${studentId}`))
  }
  // Handle delete school
  const handleRemoveSchool = async (schoolName: string, studentId: string) => {
    setIsLoading('Removing school...')
    const { msg, error } = await deleteStudent(schoolName, studentId)
    if (error) setMsg(msg)
    else {
      // change the selected school if it was deleted
      if (studentId === studentIdFromQuery) await onRemove(studentId, () => navigate(`/change`))
      else await onRemove(studentId, () => navigate(`/change?id=${studentIdFromQuery}`))
    }
    setIsLoading('')
  }

  return (
    <CenterComponent spacing={1} marginTop={0}>
      <PaperComponent title='Change School'>
        <ButtonComponent text={`New School`} width={'full'} href='/add' startIcon={<AddIcon />} />
      </PaperComponent>
      {students && students.length > 0 && (
        <PaperComponent subtitle='Select your school'>
          {students.map((s: StudentProps, i: number) => {
            const { schoolName, schoolScheduleGroups, studentId, scheduleGroupName } = s
            const schoolScheduleGroupNames: string[] =
              schoolScheduleGroups?.map((group: any) => group.schoolScheduleGroupName) ?? []
            const isSelected: boolean = studentIdFromQuery === studentId
            return (
              <Box
                key={i}
                sx={{
                  border: `1px solid ${isSelected ? theme.palette.secondary.main : theme.palette.grey[300]}`,
                  padding: 1,
                  borderRadius: 1,
                  width: '100%',
                  mb: 2,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <TextComponent size='body2'>
                    <Radio
                      size='small'
                      checked={isSelected}
                      onChange={() => handleSelectSchool(studentId)}
                      value={studentId}
                    />
                    {titleCaseSchoolNameForUI(schoolName)}
                  </TextComponent>
                  <ModalConfirmRemoveComponent
                    data_testid='DeleteOutlinedIcon'
                    title='Remove Registered School?'
                    body={`You will no longer see ${titleCaseSchoolNameForUI(schoolName)}`}
                    onClick={() => handleRemoveSchool(schoolName, studentId)}
                  />
                </Box>
                {isSelected && schoolScheduleGroupNames.length > 1 && (
                  <SelectComponent
                    key='1c'
                    label='Select School Schedule'
                    isRequired={true}
                    value={scheduleGroupName} // Update the schedule Name as selected when changed
                    options={schoolScheduleGroupNames} // Options from the fetched data
                    onChange={async (v: string) => {
                      const { msg, error } = await updateStudentSettings(
                        schoolName,
                        studentId,
                        undefined,
                        undefined,
                        undefined,
                        v,
                      )
                      if (error) setMsg(msg)
                      else {
                        setMsg({ style: 'success', text: 'Schedule updated successfully.' })
                        setTimeout(() => setMsg(undefined), 4000)
                        await onUpdate()
                      }
                    }}
                  />
                )}
              </Box>
            )
          })}
        </PaperComponent>
      )}

      {/***************** Display progress *****************/}
      {isLoading && (
        <Dialog open={true}>
          <DialogTitle>{isLoading}</DialogTitle>
          <CircularProgressWithLabel />
        </Dialog>
      )}

      {/***************** Display messages *****************/}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </CenterComponent>
  )
}

export default Index
