import ModalConfirmComponent from '../modal-confirm'
import { useState } from 'react'
import DeleteIcon from '../../images/delete.svg'
import './index.css'

interface ModalButtonComponentProps {
  title: string
  body: string
  onClick: () => Promise<void>
  data_testid?: string
}

const ModalConfirmRemoveComponent = (props: ModalButtonComponentProps): JSX.Element => {
  const { data_testid, title, body, onClick } = props ?? {}
  const [show, setShow] = useState<boolean>(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      {/* At this point we only have a need for a delete buttons - make this configurable and grow in the future    */}
      <button className='' onClick={handleShow} data-testid={data_testid}>
        <img src={DeleteIcon} alt='Delete Icon' width='25px' />
      </button>
      <ModalConfirmComponent
        isShow={show}
        title={title}
        body={body}
        actionIcon='redInfoIcon'
        actionText='Confirm'
        onActionCancel={handleClose}
        onActionSubmit={async () => {
          await onClick()
          handleClose()
        }}
      />
    </>
  )
}

export default ModalConfirmRemoveComponent
