import { Capacitor } from '@capacitor/core'
import { CountryCode } from 'libphonenumber-js'

// feature flag
export const isLegacyMobileUI: boolean = true // Used to force web UI path for mobile platform until its ready
export const isNoProfileImage: boolean = true // Used to disable profile image collection until its ready

// environment
export const baseURL: string = process.env.REACT_APP_PUBLIC_API_BASEURL || 'https://dev.carpool.school'
export const isProd = (): boolean => (process.env.REACT_APP_ENV === 'prod' ? true : false)
const platform = process.env.REACT_APP_PLATFORM_EMULATED || Capacitor.getPlatform()
export const isIOS: boolean = platform === 'ios'
export const isAndroid: boolean = platform === 'android'
export const isWeb: boolean = platform === 'web'
export const isPushNotification: boolean = (isIOS || isAndroid) && Capacitor.isPluginAvailable('PushNotifications')

// local store
export const setItem = (key: string, value: any) => localStorage.setItem(key, JSON.stringify(value))
export const getItem = (key: string): any => {
  const value = localStorage.getItem(key)
  return value && JSON.parse(value)
}
export const removeItem = (key: string) => localStorage.removeItem(key)
export const setItems = (sn: string, sae: string, sac: string) => {
  setItem('x-pool-sn', sn)
  setItem('x-pool-sae', sae)
  setItem('x-pool-sac', sac)
}
export const clearItems = () => {
  removeItem('x-pool-sn')
  removeItem('x-pool-sae')
  removeItem('x-pool-sac')
}

export const setSessionItem = (key: string, value: any) => sessionStorage.setItem(key, JSON.stringify(value))
export const getSessionItem = (key: string): any => {
  const value = sessionStorage.getItem(key)
  return value && JSON.parse(value)
}
export const removeSessionItem = (key: string) => sessionStorage.removeItem(key)

// jwt token
export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

// schoolname
export const legacySchoolsWithoutPlaceId = [
  'carnage middle',
  'franklin academy',
  'fuller elementary',
  'moore square middle',
]
export const titleCase = (str: string) => {
  const splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}
export const titleCaseSchoolNameForUI = (str: string) => {
  const endIdx = str.lastIndexOf('-') > 0 ? str.lastIndexOf('-') : str.length
  return titleCase(str.slice(0, endIdx))
}
// phoneNumber
export const defaultPhoneNumberCountry: CountryCode = 'US'

// disclaimer text
export const disclaimerText: string = `Carpool.School does not verify drivers' licenses or insurance coverage. Users are responsible for screening all participants of carpool. `

// tooltips
export const phoneCodeTooltip = (): string => 'Enter 6 digit code sent to your phone number.'
export const emailCodeTooltip = (): string => 'Enter 6 digit code sent to your email.'
export const phoneNumberTooltip = (): string => 'Valid 10 digit phone number with SMS capability is required.'
export const phoneNumberRatesTooltip = (): string => 'Message and data rates may apply.'
export const passwordTooltip = (): string => 'Password must be atleast 8 characters.'
export const reenterPasswordTooltip = (): string => 'Passwords do not match.'
export const emptyState = (): string => '-- Not Set --'
export const schoolSubscriptionLabel = (v: boolean): string =>
  v ? 'School subscription enabled' : 'Enable school-wide subscription'
export const userSubscriptionLabel = (v: boolean): string => (v ? 'Subscription enabled' : 'Enable subscription')
export const userSubscriptionTooltip = (v: boolean): string =>
  v ? '' : 'Subscribe today to connect with other families'

// limits of school admins and schedule groups
export const MIN_SCHEDULE_GROUPS: number = 1
export const MAX_SCHEDULE_GROUPS: number = 5
export const MIN_SCHOOL_ADMINS: number = 1
export const MAX_SCHOOL_ADMINS: number = 10
// date
export const getLocalDate = (): string => {
  const tzoffset: number = new Date().getTimezoneOffset() * 60000 //offset in milliseconds
  return new Date(Date.now() - tzoffset).toISOString().split('T')[0]
}

export const getTimeNumber = (date: Date): number => {
  return date.getHours() * 100 + date.getMinutes()
}

export const getFullDate = (date: Date): string => {
  const year: string = date.getFullYear().toString()
  const month: string = (date.getMonth() + 1).toString().padStart(2, '0')
  const day: string = date.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const getTimeString = (time?: number): string => {
  if (time === undefined || time < 0 || time > 2400) return ''
  let amPm: string = 'AM'
  let hr: number = Math.floor(time / 100)
  if (hr >= 12) {
    if (hr > 12) hr -= 12
    amPm = 'PM'
  }
  const mn: string = (time % 100).toLocaleString(undefined, { minimumIntegerDigits: 2 })
  return `${hr}:${mn} ${amPm}`
}

export const getTimeStringFromNumber = (time?: number): string => {
  if (time === undefined || time < 0 || time > 2400) return '00:00'
  const hr: string = Math.floor(time / 100).toLocaleString(undefined, { minimumIntegerDigits: 2 })
  const mn: string = (time % 100).toLocaleString(undefined, { minimumIntegerDigits: 2 })
  return `${hr}:${mn}`
}

export const getFinalTime = (dismissalTime: number, customTime?: number, defaultTime?: number | null): number => {
  // Return the user configured dropoff time, and fallback to defaults if not available
  if (defaultTime === undefined || defaultTime === null)
    return customTime ?? dismissalTime // defaults to dismissal time if user defaults are undefined or null
  else if (defaultTime < 0) return customTime ?? -1
  // defaults to a special value of -1 to indicate not carpooling if user defaults are below lower limit
  else if (defaultTime > 2400)
    return customTime ?? dismissalTime // defaults to dismissal time if user defaults are above upper limit
  else return customTime ?? defaultTime // defaults to the user default
}

export const getTimeNumberFromString = (time?: string): number => {
  const regEx = /^\d{2}:\d{2}$/
  if (!time || !time.match(regEx)) return 0
  return Number(time.slice(0, 2)) * 100 + Number(time.slice(3, 5))
}

export const isDateValid = (date?: string): boolean => {
  const regEx = /^\d{4}-\d{2}-\d{2}/
  if (!date || !date.match(regEx)) return false // Invalid format
  const d = new Date(date)
  const dNum = d.getTime()
  if (!dNum && dNum !== 0) return false // NaN value, Invalid date
  return d.toISOString().slice(0, date.length) === date
}

export const isDateInLastMonth = (d?: string): boolean => {
  return d ? new Date(d).getTime() > Date.now() - 30 * 24 * 60 * 60 * 1000 : false
}

// map
export const METERS_TO_MILES: number = 0.000621371192

// billing
export const isBillingDisabledForSchool: boolean = true
export const isBillingDisabledForFamily: boolean = true
export const MIN_REQUIRED_REGISTRATIONS: number = 1
export const MAX_ALLOWED_REGISTRATIONS: number = 500
