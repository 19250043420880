import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Box from '@mui/material/Box'
import { PaperFlowComponent } from 'components/PaperFlowComponent'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import InputComponent from 'components/input'
import TextComponent from 'components/text'
import ToastComponent from 'components/toast'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { setSessionItem } from 'utils/constants'
import { getSchool, verifySchoolCode } from 'utils/schools'
import { registerStudent } from 'utils/students'
import { MessageProps, UserProps } from 'utils/types'
import './index.css'
import { LinearProgress } from '@mui/material'

interface Props {
  user?: Pick<UserProps, 'relation'>
  onAdd: (ssi: string, cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: School Name, User Relation and onAdd Callbck
  const { user, onAdd } = props ?? {}
  const [searchParams] = useSearchParams()
  const schoolName: string = searchParams.get('sn') || ''
  const userStudentRelationship: string = user?.relation ?? ''

  // states
  const [schoolAdmins, setSchoolAdmins] = useState<string[]>()
  const [schoolScheduleGroupNames, setSchoolScheduleGroupNames] = useState<string[]>([])
  const [schoolStatus, setSchoolStatus] = useState<string>('')
  const [schoolCode, setSchoolCode] = useState<string>('')
  const [msg, setMsg] = useState<MessageProps>()

  // hooks
  useEffect(() => {
    (async () => {
      const { msg, data, error } = await getSchool(schoolName)
      if (error) setMsg(msg)
      else if (data) {
        setSchoolStatus(data.status as string)
        setSchoolAdmins(data?.schoolAdmins as string[])
        setSchoolScheduleGroupNames(
          (data?.schoolScheduleGroups as any[])?.map((group: any) => group.schoolScheduleGroupName),
        )
      }
    })()
  }, [schoolName])

  // handlers
  const onClose = () => navigate('/')
  const onBack = () => navigate(-1)
  const onNext = async (): Promise<any> => {
    const { msg, error } = await verifySchoolCode(schoolName, schoolCode)
    if (error) setMsg(msg)
    else {
      // Save school name and code for following steps
      setSessionItem('x-pool-sn', schoolName)
      setSessionItem('x-pool-sa', schoolAdmins)
      setSessionItem('x-pool-sc', schoolCode)
      setSessionItem('x-pool-ssgn', schoolScheduleGroupNames)
      if (schoolScheduleGroupNames.length > 1) {
        navigate('/add/school/schedule')
      } else {
        setSessionItem('x-pool-sg', schoolScheduleGroupNames[0])
        navigate('/add/school')
      }
    }
  }
  const onSubmit = async (): Promise<any> => {
    const { msg, error } = await verifySchoolCode(schoolName, schoolCode)
    if (error) setMsg(msg)
    else {
      // Register Student
      const { msg, data, error } = await registerStudent(
        schoolName,
        schoolCode,
        userStudentRelationship,
        schoolScheduleGroupNames[0],
        { address: '' },
        { address: '' },
      )
      if (error) setMsg(msg)
      else {
        // Return
        const id: string = (data?.studentId as string) ?? ''
        onAdd(id, () => {
          navigate(`/students?id=${id}`)
        })
      }
    }
  }

  if (schoolStatus === 'verify_code') {
    return (
      <CenterComponent onClose={onClose} onBack={onBack} spacing={1}>
        <PaperFlowComponent title={'Verify Your Enrollment'} subtitle={`Enter your school code`} />
        <InputComponent
          type='text'
          placeholder='Enter school code'
          label='Verification code'
          data_testid='school_verification_code_testid'
          onChange={(v: string) => setSchoolCode(v.trimEnd())}
        />
        {/* Determine next route based on school and user input states */}
        {schoolScheduleGroupNames.length === 1 && userStudentRelationship ? (
          <ButtonComponent
            text='Register'
            disable={!schoolCode}
            endIcon={<ArrowForwardIcon />}
            data_testId='register-btn'
            onClick={onSubmit}
          />
        ) : (
          <ButtonComponent
            text='Next'
            disable={!schoolCode}
            data_testid='next-btn-testid'
            endIcon={<ArrowForwardIcon />}
            onClick={() => onNext()}
          />
        )}
        {schoolAdmins && schoolAdmins?.length >= 1 && (
          <Box pt={3}>
            <TextComponent size='body2'>
              No school code? Contact your school admin{` at ${schoolAdmins[0].toString()}`}
            </TextComponent>
          </Box>
        )}
        {msg && (
          <ToastComponent
            style={msg?.style}
            heading={msg?.heading}
            text={msg?.text}
            onClose={() => setMsg(undefined)}
          />
        )}
      </CenterComponent>
    )
  } else if (schoolStatus === 'verify_manual') {
    return (
      <CenterComponent onClose={onClose} onBack={onBack} spacing={1}>
        <PaperFlowComponent
          title={'Self-Registration Not Available'}
          subtitle={`Please contact your school administrator to complete the registration process.`}
        />
      </CenterComponent>
    )
  } else if (schoolStatus === 'disabled') {
    return (
      <CenterComponent onClose={onClose} onBack={onBack} spacing={1}>
        <PaperFlowComponent
          title={'School not Enrolled'}
          subtitle={`Ask school/pta to enroll for carpool.school service.`}
        />
      </CenterComponent>
    )
  } else if (schoolStatus === 'missing') {
    return (
      <CenterComponent onClose={onClose} onBack={onBack} spacing={1}>
        <PaperFlowComponent
          title={'School Missing'}
          subtitle={`Contact us at support@carpool.school to add your school.`}
        />
      </CenterComponent>
    )
  } else return <LinearProgress />
}

export default Index
