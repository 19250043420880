import { useState } from 'react'
import { Link } from 'react-router-dom'

const ButtonMobileComponent = (props: any) => {
  const {
    icon,
    id,
    variant,
    color,
    disable,
    href,
    onClick,
    size,
    text,
    width,
    data_testId,
    iconPosition = 'left',
    ...rest
  } = props ?? {}

  const [isLoading, setIsLoading] = useState(false)

  const buttonDisabled = disable || isLoading

  const buttonStyles = `${width} rounded-lg flex ${
    color ?? 'bg-custom-yellow'
  } h-[44px] py-[6px] pl-[10px] pr-[16px] justify-center items-center gap-[6px] self-stretch ${rest.className || ''} `

  const allstyles = `${buttonStyles} ${buttonDisabled ? 'cursor-not-allowed opacity-75' : 'cursor-pointer'} `

  const iconElement = icon || null

  const content = (
    <>
      {iconPosition === 'left' && iconElement}
      <div className={`text-[#474747] text-sm font-medium leading-normal ${buttonDisabled ? 'text-dis' : ''}`}>
        {isLoading ? 'Loading...' : text}
      </div>
      {iconPosition === 'right' && iconElement}
    </>
  )

  let children: JSX.Element
  if (href) {
    children = (
      <Link id={id} to={href} className={buttonStyles} data-testid={data_testId} {...rest}>
        {content}
      </Link>
    )
  } else {
    children = (
      <button
        id={id}
        className={allstyles}
        disabled={buttonDisabled}
        data-testid={data_testId}
        onClick={async () => {
          setIsLoading(true)
          if (onClick) await onClick()
          setIsLoading(false)
        }}
        {...rest}
      >
        {content}
      </button>
    )
  }

  return <>{children}</>
}

export default ButtonMobileComponent
