import AddIcon from '@mui/icons-material/Add'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import LightModeIcon from '@mui/icons-material/LightMode'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import { GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import BadgeComponent from 'components/badge'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import DialogUserComponent from 'components/dialog-user'
import FilterComponent from 'components/filter'
import MapComponent from 'components/map'
import MetricsComponent from 'components/metrics'
import NavComponent from 'components/nav'
import RadioComponent from 'components/radio'
import TableComponent from 'components/table'
import TextComponent from 'components/text'
import ToastComponent from 'components/toast'
import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import { getDistance } from 'geolib'
import { parsePhoneNumber } from 'libphonenumber-js/max'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  METERS_TO_MILES,
  getFinalTime,
  getLocalDate,
  getTimeString,
  isDateValid,
  parseJwt,
  titleCase,
} from 'utils/constants'
import { getMarker } from 'utils/map'
import { getSchoolPool } from 'utils/schools'
import { updateStudentMessages } from 'utils/students'
import {
  LocationProps,
  MarkerProps,
  MessageProps,
  ScheduleGroupProps,
  SchoolPoolProps,
  SchoolPoolStudentProps,
  StudentProps,
  UserProps,
} from 'utils/types'
import './index.css'

dayjs.extend(customParseFormat)

// No Schools - Show New School
const DashboardEmptyComponent = (props: any): JSX.Element => {
  return (
    <CenterComponent>
      <ButtonComponent text={`New School`} href='/add' startIcon={<AddIcon />} data_testid='New-School-testid' />
    </CenterComponent>
  )
}

// No Subscriptions - Show Metrics
const DashboardMetricsComponent = (props: any): JSX.Element => {
  const { schoolStats } = props

  // Hooks
  const theme = useTheme()
  return (
    <>
      <Paper elevation={3} sx={{ p: 1, m: 1, backgroundColor: theme.palette.secondary.light }}>
        <TextComponent size='p' fontStyle='italic'>
          {`To connect with other families, please contact your school admin or email `}
          <NavComponent href='mailto:support@carpool.school' text='support@carpool.school' />
          {`.`}
        </TextComponent>
      </Paper>
      <MetricsComponent schoolStats={schoolStats} />
    </>
  )
}

const DashboardComponent = (props: any): JSX.Element => {
  // INPUTS: User, Date, Pool, Callback
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { date, onUpdate } = props
  const { phoneNumber } = props.user as UserProps
  const {
    studentId,
    schoolName,
    schoolLocation,
    schoolScheduleGroups,
    schoolPoolRadius,
    pickupLocation,
    dropoffLocation,
  } = props.student as StudentProps
  const getSchoolEndTime = (sgn: string) =>
    schoolScheduleGroups?.find((v: ScheduleGroupProps) => v?.schoolScheduleGroupName === sgn)
      ?.schoolScheduleGroupEndTime ?? 0
  const isNeedAMPool: boolean = pickupLocation && pickupLocation?.lat && pickupLocation?.lng ? true : false
  const isNeedPMPool: boolean = dropoffLocation && dropoffLocation?.lat && dropoffLocation?.lng ? true : false
  const isNeedPool: boolean = isNeedAMPool || isNeedPMPool

  // states
  const [isAMPool, setIsAMPool] = useState<boolean>(isNeedPMPool ? false : true)
  const [poolDate, setPoolDate] = useState<string>(isDateValid(date) ? date : getLocalDate())
  const homeLocation: LocationProps = (isAMPool ? pickupLocation : dropoffLocation) as LocationProps
  const [schoolPool, setSchoolPool] = useState<SchoolPoolProps>()
  const [filterableActivities, setFilterableActivities] = useState<Set<string>>(new Set())
  const [selectedActivities, setSelectedActivities] = useState<Set<string>>(new Set())
  const [centerMarker, setCenterMarker] = useState<MarkerProps>(getMarker(homeLocation, 'You', schoolPoolRadius))
  const [markers, setMarkers] = useState<MarkerProps[]>([getMarker(schoolLocation, 'School')])
  const [header, setHeader] = useState<GridColDef[]>()
  const [body, setBody] = useState<GridRowsProp>()
  const [selectedGroupNames, setSelectedGroupNames] = useState<Set<string>>(new Set()) // Select multiple schedule Group Names
  const [msg, setMsg] = useState<MessageProps>()

  // Hooks
  useEffect(() => {
    if (isNeedPool) {
      (async () => {
        const { data, error } = await getSchoolPool(schoolName, isAMPool ? undefined : poolDate)
        if (!error) setSchoolPool(data as SchoolPoolProps)
      })()
      if (isAMPool) {
        setSelectedActivities(new Set())
        window.history.replaceState(null, '', `/students?id=${studentId}`)
      } else window.history.replaceState(null, '', `/students?id=${studentId}&date=${poolDate}`)
    }
  }, [isAMPool, poolDate]) // eslint-disable-line

  useEffect(() => {
    const homeLocation: LocationProps = (isAMPool ? pickupLocation : dropoffLocation) as LocationProps
    setCenterMarker(getMarker(homeLocation, 'You', schoolPoolRadius))

    if (schoolPool) {
      // Pool - Extract, Filter, Transform and Order
      const homeLat: number = homeLocation.lat as number
      const homeLng: number = homeLocation.lng as number
      const rows: any[] = [],
        pins: MarkerProps[] = []
      const allDistinctStudentActivities: Set<string> = new Set()
      schoolPool.students.forEach((v: SchoolPoolStudentProps, i: number) => {
        const {
          userName,
          userPhoneNumber,
          userRelationship,
          studentId,
          position,
          dropoffTimeDefault,
          dropoffTime,
          studentActivities,
          seatsAvailable,
          comments,
          scheduleGroupName,
        } = v
        const time: number = getFinalTime(getSchoolEndTime(scheduleGroupName), dropoffTime, dropoffTimeDefault)
        const isScheduled = isAMPool || time >= 0

        // Build list of activities
        studentActivities?.forEach((v) => allDistinctStudentActivities.add(v))
        // Filter out students not looking for carpool, and without matching selected activities
        if (
          (!selectedGroupNames.size || selectedGroupNames.has(scheduleGroupName)) &&
          v.userPhoneNumber !== phoneNumber &&
          position?.lat &&
          position?.lng &&
          isScheduled &&
          (isAMPool || !selectedActivities.size || studentActivities?.find((v) => selectedActivities.has(v)))
        ) {
          const scheduleName: string | undefined =
            schoolPool?.schoolScheduleGroups?.length && schoolPool.schoolScheduleGroups.length > 1
              ? scheduleGroupName
              : undefined
          const relation: string = ['Parent/Stepparent', 'Mother', 'Father'].includes(userRelationship)
            ? 'Parent'
            : userRelationship
          const distance: number = getDistance({ lat: homeLat, lng: homeLng }, { lat: position.lat, lng: position.lng })
          const activities: string[] | undefined = Array.isArray(studentActivities) ? studentActivities : undefined
          const distanceMiles: string = Math.round(distance * METERS_TO_MILES * 10) / 10 + ' miles'
          const pmPickupTime: string | undefined = !isAMPool ? getTimeString(time) : undefined
          const phone: string = parsePhoneNumber(userPhoneNumber).formatNational()
          const onAction = async () => {
            const { msg, error: err1 } = await updateStudentMessages(
              schoolName,
              props.student.studentId,
              studentId,
              isAMPool ? undefined : poolDate,
            )
            setMsg(err1 ? msg : { style: 'success', text: `Notified ${userName}` })
            const { data, error: err2 } = await getSchoolPool(schoolName, isAMPool ? undefined : poolDate)
            if (!err2) setSchoolPool(data as SchoolPoolProps)
          }
          const actions: JSX.Element = (
            <DialogUserComponent
              userName={userName}
              userRelationship={relation}
              contact={phone}
              scheduleName={scheduleName}
              distance={distanceMiles}
              seatsAvailable={seatsAvailable}
              time={pmPickupTime}
              activities={activities}
              comments={comments}
              onClick={onAction}
              data_testid='icon-button'
            />
          )

          pins.push({
            position,
            iconPath: '/user_pin.svg',
            iconSize: { width: 45, height: 45 }, // Default size
            infoWindowId: `btn-${i}`,
            infoWindowContent: titleCase(userName),
            draggable: false,
          })
          rows.push({
            name: <BadgeComponent text={titleCase(`${userName} (${distanceMiles})`)} />,
            distance,
            pmPickupTime: pmPickupTime ? <BadgeComponent text={pmPickupTime} /> : <></>,
            actions,
          })
        }
      })
      setFilterableActivities(allDistinctStudentActivities)
      setMarkers([getMarker(schoolLocation, 'School'), ...pins])
      if (isAMPool) {
        setHeader([
          {
            field: 'name',
            headerName: 'Name (proximity to you)',
          },
          {
            field: 'actions',
            headerName: 'Actions',
          },
        ])
        setBody(rows.sort((a, b) => (a.distance < b.distance ? -1 : 1)))
      } else {
        setHeader([
          {
            field: 'name',
            headerName: 'Family (Proximity to You)',
          },
          {
            field: 'pmPickupTime',
            headerName: 'Pickup Time',
          },
          {
            field: 'actions',
            headerName: 'Actions',
          },
        ])
        setBody(rows.sort((a, b) => (a.distance < b.distance ? -1 : 1)))
      }
    }
  }, [schoolPool, isAMPool, selectedActivities, selectedGroupNames]) // eslint-disable-line

  // User data not loaded yet
  if (isNeedPool && !body) return <LinearProgress />

  return (
    <>
      {/***************** Controls *****************/}
      <Paper
        variant='outlined'
        sx={{
          p: 1,
          my: 1,
          display: 'flex',
          gap: '10px',
          justifyContent: 'flex-start',
          '@media (max-width: 490px)': {
            flexDirection: 'column',
          },
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 1 }}>
            <RadioComponent
              row={true}
              radios={[
                {
                  disable: !isNeedAMPool,
                  isChecked: isNeedAMPool && isAMPool ? true : false,

                  label: isNeedAMPool ? (
                    <Box
                      display='flex'
                      alignItems='center' // Vertically aligns items
                      gap={1} // Adds space between icon and text
                    >
                      <LightModeOutlinedIcon sx={{ fontSize: 20 }} />
                      <TextComponent size='body1'>Morning carpool</TextComponent>
                    </Box>
                  ) : (
                    <Button
                      color='warning'
                      href={`/morning?id=${studentId}`}
                      sx={{ fontSize: 12 }}
                      endIcon={<EditOutlinedIcon />}
                    >
                      <LightModeOutlinedIcon sx={{ fontSize: 20 }} />
                      <TextComponent size='body1'>Morning carpool (Enter address)</TextComponent>
                    </Button>
                  ),

                  onChange: (v: any) => setIsAMPool(v.target.checked),
                },
                {
                  disable: !isNeedPMPool,
                  isChecked: isNeedPMPool && !isAMPool ? true : false,
                  label: isNeedPMPool ? (
                    <Box
                      display='flex'
                      alignItems='center' // Vertically aligns items
                      gap={1} // Adds space between icon and text
                    >
                      <LightModeIcon sx={{ fontSize: 20 }} />
                      <TextComponent size='body1'>Afternoon carpool</TextComponent>
                    </Box>
                  ) : (
                    <Button
                      color='warning'
                      href={`/afternoon?id=${studentId}`}
                      sx={{ fontSize: 12 }}
                      endIcon={<EditOutlinedIcon />}
                    >
                      <LightModeIcon sx={{ fontSize: 20 }} />
                      <TextComponent size='body1'>Afternoon carpool (Enter address)</TextComponent>
                    </Button>
                  ),
                  onChange: (v: any) => setIsAMPool(!v.target.checked),
                },
              ]}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
            {/***************SCHOOL SCHEDULE FILTER**********/}
            {isNeedPool && schoolPool?.schoolScheduleGroups && schoolPool.schoolScheduleGroups?.length > 1 && (
              <FilterComponent
                label='Schedule'
                options={new Set(schoolPool?.schoolScheduleGroups?.map((group) => group.schoolScheduleGroupName) || [])}
                onChange={(v) => setSelectedGroupNames(v)}
                testid='group-filter'
              />
            )}
            {/***************AFTERNOON ACTIVITIES FILTER**********/}
            {isNeedPMPool && !isAMPool && filterableActivities && filterableActivities?.size > 0 && (
              <FilterComponent
                label='Activity'
                options={filterableActivities}
                onChange={(v) => setSelectedActivities(v)}
                testid='activity-filter'
              />
            )}
            {/***************AFTERNOON DATEPICKER**********/}
            {isNeedPMPool && !isAMPool && (
              <>
                <DatePicker
                  value={dayjs(poolDate)}
                  formatDensity='spacious'
                  onChange={async (v: Dayjs | null) => {
                    if (v) setPoolDate(dayjs(v).format('YYYY-MM-DD'))
                  }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      variant: 'standard',
                      autoFocus: true,
                      inputProps: {
                        readOnly: true,
                        'data-testid': 'calender-testid', // Set data-testid here
                      },
                    },
                  }}
                />
              </>
            )}
          </Box>
        </Box>
      </Paper>

      {/***************** Map View *****************/}
      {isNeedPool && <MapComponent centerMarker={centerMarker} markers={markers} />}

      {/***************** Table View *****************/}
      {body && body?.length > 0 ? <TableComponent header={header} body={body} isExpandableRow={true} /> : <></>}

      {/***************** Display messages *****************/}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </>
  )
}

interface Props {
  tokens: any
  students: StudentProps[]
  processQuery: (id: string) => string
  onUpdate: (cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()

  // Inputs: Tokens, Students, Selected Student Id, and Callback
  const { phone_number, name } = parseJwt(props.tokens.IdToken)
  const { students, processQuery, onUpdate } = props

  // We call this component without student id query search parameters in quite a few places in the code
  const studentIdFromQuery = searchParams.get('id') ?? ''
  const date = searchParams.get('date')
  useEffect(() => {
    setSearchParams({ id: processQuery(studentIdFromQuery), ...(date ? { date } : {}) })
  }, [studentIdFromQuery, date, processQuery, setSearchParams])

  // Build props for child component
  const user: UserProps = { phoneNumber: phone_number, name }
  const student: StudentProps | undefined = students?.find((s: StudentProps) => s.studentId === studentIdFromQuery)
  const { isSchoolSubscription, isStudentSubscription, schoolStats } = student ?? {}

  if (student) {
    if (isSchoolSubscription || isStudentSubscription) {
      // Matching student with subscription
      return <DashboardComponent user={user} student={student} onUpdate={onUpdate} date={date} />
    } else {
      // Matching student without subscription
      return <DashboardMetricsComponent schoolStats={schoolStats} />
    }
  } else {
    // No matching student
    return <DashboardEmptyComponent />
  }
}

export default Index
