import {
  LocationClient,
  SearchPlaceIndexForTextCommand,
  SearchPlaceIndexForTextCommandOutput,
} from '@aws-sdk/client-location'
import { withAPIKey } from '@aws/amazon-location-utilities-auth-helper'
import { LocationProps, MarkerProps } from 'utils/types'

// Singleton instance of AWS LocationClient
let awsLocationClientInstance: LocationClient

const countryCodes: { [country: string]: string } = {
  'United States': 'USA',
  Canada: 'Canada',
  'United Kingdom': 'UK',
  // Add more countries and their codes here
}

export const replaceCountryWithCode = (str: string): string => {
  // Create a regular expression that matches any of the country names at the end of the string
  const regex = new RegExp(`, (${Object.keys(countryCodes).join('|')})$`)

  // Replace the matched country with its code
  return str.replace(regex, (_, country: string) => `, ${countryCodes[country as keyof typeof countryCodes]}`)
}

export const getAwsLocationClient = async () => {
  if (!awsLocationClientInstance) {
    // Create an authentication helper instance using an API key
    const authHelper = await withAPIKey(process.env.REACT_APP_AWS_PLACE_INDEX_KEY || '')

    awsLocationClientInstance = new LocationClient({
      region: 'us-east-1', // Region containing Amazon Location resource
      ...authHelper.getLocationClientConfig(), // Configures the client to use API keys when making supported requests
    })
  }
  return awsLocationClientInstance
}

export const geocode = async (address: string): Promise<LocationProps> => {
  try {
    const awsLocationClient = await getAwsLocationClient()
    const data: SearchPlaceIndexForTextCommandOutput = await awsLocationClient.send(
      new SearchPlaceIndexForTextCommand({
        IndexName: process.env.REACT_APP_AWS_PLACE_INDEX,
        Text: address,
        MaxResults: 1,
      }),
    )
    if (
      data?.Results &&
      data.Results[0] &&
      data.Results[0].Place &&
      data.Results[0].Place.Label &&
      data.Results[0].Place?.Geometry &&
      data.Results[0].Place.Geometry.Point?.length &&
      data.Results[0].Place.Geometry.Point?.length >= 2
    ) {
      const address: string = replaceCountryWithCode(data.Results[0].Place.Label)
      const result: LocationProps = {
        address,
        lat: data.Results[0].Place.Geometry.Point[1],
        lng: data.Results[0].Place.Geometry.Point[0],
        placeId: data.Results[0].PlaceId,
      }
      return result
    } else return { address }
  } catch (error) {
    console.error('Error fetching suggestions:', error)
    return { address }
  }
}

export const getMarker = (
  location: LocationProps,
  label: string,
  radius?: number,
  isDraggable?: boolean,
  iconSize?: { width: number; height: number },
): MarkerProps => {
  const lat: number = location?.lat as number
  const lng: number = location?.lng as number
  const address: string = location?.address as string
  const draggable: boolean = isDraggable ?? false

  if (label === 'School') {
    return {
      position: { lat, lng },
      label,
      iconPath: '/school_pin.svg',
      iconSize: iconSize || { width: 45, height: 45 }, // Default size
      infoWindowContent: `School address is ${address}`,
      draggable,
    }
  } else if (label === 'You') {
    return {
      position: { lat, lng },
      label,
      iconPath: '/push_pin.svg',
      iconSize: iconSize || { width: 45, height: 45 }, // Default size
      infoWindowContent: `Your address is ${address}`,
      draggable,
      circleRadius: radius && radius > 0 ? radius : undefined,
    }
  } else {
    return {
      position: { lat, lng },
      draggable,
    }
  }
}
