import hide from 'images/hide.png'
import show from 'images/show.png'
import { useState } from 'react'

interface InputMobileComponentProps {
  isRequired?: boolean
  isDisabled?: boolean
  isError?: boolean
  type?: string
  labeltext?: string
  placeholder?: string
  value?: any
  defaultValue?: any
  helperText?: string
  marginLeft?: string
  onChange: (v: string) => void
  data_testid?: string
  min?: number
  step?: number
  max?: number
  isReadOnly?: boolean
}

const InputMobileComponent = (props: InputMobileComponentProps): JSX.Element => {
  const {
    isRequired,
    isDisabled,
    isError,
    type,
    labeltext,
    placeholder,
    value,
    defaultValue,
    helperText,
    marginLeft,
    onChange,
    data_testid,
    isReadOnly,
    ...rest
  } = props ?? {}

  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  const inputType = showPassword && type === 'Password' ? 'text' : type

  const inputElement =
    labeltext === 'Comments' ? (
      <>
        <textarea
          data-testid={data_testid}
          onChange={(e) => onChange(e.target.value)}
          value={value || defaultValue}
          placeholder={placeholder}
          className={`w-full rounded-lg border ${isError ? 'border-red-500' : 'border-gray-300'} 
          bg-white shadow-xs focus:outline-none h-36 p-[10px] pr-[14px] items-start ${marginLeft}`}
          {...rest}
        />
        <div className='text-gray-400 text-sm font-medium leading-normal'>{helperText}</div>
      </>
    ) : (
      <div className={`w-full ${type === 'Password' ? 'relative' : ''}`}>
        <input
          type={inputType}
          data-testid={data_testid}
          onChange={(e) => onChange(e.target.value)}
          value={value || defaultValue}
          placeholder={placeholder}
          className={`w-full rounded-lg border ${isError ? 'border-red-500' : 'border-gray-300'}  ${
            isReadOnly ? 'text-disable opacity-65' : 'bg-white'
          } bg-white shadow-xs focus:outline-none h-11 p-[10px] pr-12 items-center ${marginLeft}`}
          {...rest}
          readOnly={isReadOnly}
        />
        {/*  FIXME: Show hide password */}
        {type === 'Password' && (
          <button
            type='button'
            onClick={togglePasswordVisibility}
            className='absolute right-2 top-1/2 transform -translate-y-1/2 px-2 focus:outline-none'
          >
            <img
              className='w-5 h-5'
              src={showPassword ? show : hide}
              alt={showPassword ? 'Hide password' : 'Show password'}
            />
          </button>
        )}
      </div>
    )

  return (
    <div className='flex flex-col items-start gap-2 w-full'>
      <label className='text-gray-600 text-sm font-medium leading-[1.42857]'>{labeltext}</label>
      {inputElement}
    </div>
  )
}

export default InputMobileComponent
