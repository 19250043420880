import React from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'

interface BackComponentProps {
  text?: string
  onClick?: () => void
}

const BackComponent = (props: BackComponentProps): JSX.Element => {
  const navigate: NavigateFunction = useNavigate()
  const { text, onClick } = props ?? {}

  const handleBackClick = () => {
    if (onClick) onClick()
    else navigate(-1)
  }

  return (
    <div className='flex p-4 pl-5 pr-5  gap-3 items-center'>
      <div
        className='flex items-center gap-2.5 cursor-pointer'
        onClick={handleBackClick}
        style={{ padding: '5px' }} // Add padding to increase clickable area
        data-testid='back-button-testid'
      >
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 16 16' fill='none'>
          <path d='M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z' fill='#68727D' />
        </svg>
      </div>
      <div className='text-[color:var(--Color-Tokens-Text-Modes-Base-Second,#68727D)] text-xl font-semibold leading-[24px]'>
        {text}
      </div>
    </div>
  )
}

export default BackComponent
