import Box from '@mui/system/Box'
import { PaperFlowComponent } from 'components/PaperFlowComponent'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import CheckboxComponent from 'components/checkbox'
import InputComponent from 'components/input'
import ToastComponent from 'components/toast'
import { formatIncompletePhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max'
import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { baseURL, defaultPhoneNumberCountry, isWeb } from 'utils/constants'
import { MessageProps } from 'utils/types'
import { signInUser } from 'utils/users'
import { isValidPassword, isValidPhone } from 'utils/validations'
import './index.css'
import TextComponent from 'components/text'
import NavComponent from 'components/nav'
import InputMobileComponent from 'components/input-mobile'
import ButtonMobileComponent from 'components/button-mobile'

interface Props {
  onSignIn: (cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: Callback
  const { onSignIn } = props ?? {}

  // states
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>('')
  const [userPassword, setUserPassword] = useState<string>('')
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
  const [msg, setMsg] = useState<MessageProps>()

  // handlers
  const onClose = () => navigate('/')

  const formattedPhoneNumber: string = formatIncompletePhoneNumber(userPhoneNumber, defaultPhoneNumberCountry)
  const e164PhoneNumber: string = isValidPhone(userPhoneNumber, true)
    ? parsePhoneNumber(userPhoneNumber, defaultPhoneNumberCountry).format('E.164')
    : ''

  const isDisable: boolean = !isValidPhone(userPhoneNumber, true) || !isValidPassword(userPassword, true)
  return isWeb ? (
    <CenterComponent onClose={onClose}>
      <PaperFlowComponent title={'Welcome Back!'} subtitle={`Please sign in to access your account.`} />
      <InputComponent
        type='tel'
        data_testid='Signin-Phone-number-testid'
        label='Phone Number'
        isRequired={true}
        isError={!isValidPhone(userPhoneNumber)}
        onChange={(v: string) => setUserPhoneNumber(v)}
        value={isValidPhone(userPhoneNumber) ? formattedPhoneNumber : userPhoneNumber}
        width='80%'
      />
      <InputComponent
        type={isShowPassword ? 'type' : 'password'}
        data_testid='Signin-password-testid'
        label='Password'
        isRequired={true}
        onChange={(v: string) => setUserPassword(v)}
        value={userPassword ?? ''}
        width='80%'
      />
      <CheckboxComponent
        type='checkbox'
        color='warning'
        label='Show Password'
        isChecked={isShowPassword ? true : false}
        onChange={(v: any) => setIsShowPassword(v.target.checked)}
      />
      <ButtonComponent
        text='Sign In'
        disable={isDisable}
        mt={3}
        mb={2}
        onClick={async () => {
          const { msg, error } = await signInUser(e164PhoneNumber, userPassword)
          if (error) setMsg(msg)
          else {
            // Optimisitic UI for sign in. We trigger the callback to retrieve data from backend and go to next page without waiting for completion.
            onSignIn()
            navigate('/')
          }
        }}
      />
      <Box pt={3}>
        <TextComponent size='body2'>
          By continuing, you have read and agree to our{' '}
          <span style={{ color: '#beac48', fontWeight: 'bold', textDecoration: 'underline' }}>
            <NavComponent href={`${baseURL}/home/index.html#terms`} text='Terms and Conditions' isOpenNewTab={true} />
          </span>{' '}
          and{' '}
          <span style={{ color: '#beac48', fontWeight: 'bold', textDecoration: 'underline' }}>
            <NavComponent href={`${baseURL}/home/index.html#privacy`} text='Privacy Policy' isOpenNewTab={true} />
          </span>
          .
        </TextComponent>
      </Box>
      <Box display='flex' flexDirection='column' paddingTop={5}>
        <ButtonComponent size='small' variant='text' color='warning' text='Forgot Password?' href='/reset' />
        <ButtonComponent
          size='small'
          variant='text'
          color='warning'
          text='Are you a new user? Sign Up here'
          href='/signup'
        />
      </Box>
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </CenterComponent>
  ) : (
    <>
      <div className='flex items-center justify-center min-h-screen px-[var(--10,20px)] py-10'>
        <div className='flex h-[770px] px-[var(--16,16px)] py-[var(--36,36px)] flex-col items-start flex-[1_0_0] rounded-[var(--xl,12px)] border border-[var(--Accessible-Yellow,#E5C265)] bg-white max-w-lg'>
          <div className='flex flex-col items-start flex-1 self-stretch'>
            <div className='flex flex-col items-center gap-[var(--32,32px)] self-stretch py-3 px-0'>
              <div className='flex flex-col items-start self-stretch'>
                <div className='flex flex-col items-start self-stretch py-3 px-0'>
                  <div className='text-[var(--Headings,#474747)] text-center font-bold text-xl leading-[28px]'>
                    Welcome Back!
                  </div>
                </div>
                <div className='text-[var(--Texts,#797979)] text-md font-normal'>
                  Please sign in to access your account.
                </div>
              </div>
            </div>
            <div className='flex flex-col items-center gap-[var(--18,18px)] self-stretch py-[var(--16,16px)] px-0'>
              <div className='flex flex-col items-center self-stretch py-4 px-0 gap-[var(--18,18px)]'>
                <InputMobileComponent
                  labeltext='Phone'
                  type='tel'
                  isError={!isValidPhone(userPhoneNumber)}
                  onChange={(v: string) => setUserPhoneNumber(v)}
                  value={isValidPhone(userPhoneNumber) ? formattedPhoneNumber : userPhoneNumber}
                  isRequired={true}
                  marginLeft={'px-16'}
                  data_testid='Signin-Phone-number-testid'
                />
                <div className='flex px-3 py-2 pl-3.5 pr-3 justify-start items-center self-stretch absolute mt-7'>
                  <div className='text-headings text-bodyLg font-normal'>US</div>
                  <div className='ml-2'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                      <path d='M5 7.5L10 12.5L15 7.5' stroke='#666666' />
                    </svg>
                  </div>
                </div>
                <InputMobileComponent
                  labeltext='Password'
                  type='Password'
                  onChange={(v: string) => setUserPassword(v)}
                  value={userPassword}
                  isRequired={true}
                  data_testid='Signin-password-testid'
                />
                <ButtonMobileComponent color='transparent' text='Forgot Password ?' href='/reset' />
              </div>
              <ButtonMobileComponent
                id='find-school-button'
                text='Sign in'
                iconPosition='right'
                isLoading={false}
                disable={isDisable}
                data_testId='Signin-button-testid'
                icon={
                  <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
                    <path
                      d='M10.5 3.33337L9.32498 4.50837L13.975 9.16671H3.83331V10.8334H13.975L9.32498 15.4917L10.5 16.6667L17.1666 10L10.5 3.33337Z'
                      fill='#666666'
                    />
                  </svg>
                }
                tooltip='Click to find a school'
                onClick={async () => {
                  const { msg, error } = await signInUser(e164PhoneNumber, userPassword)
                  if (error) setMsg(msg)
                  else {
                    // Optimisitic UI for sign in. We trigger the callback to retrieve data from backend and go to next page without waiting for completion.
                    onSignIn()
                    navigate('/')
                  }
                }}
              />
              <div className='flex justify-center items-center self-stretch '>
                <p className='text-[var(--Texts,#797979)] text-center text-base font-normal leading-6'>
                  Are you a new user?
                </p>
                <ButtonMobileComponent color='transparent' text='Sign up here' href='/signup' />
              </div>
            </div>
          </div>

          <div className='text-[#797979] text-center text-[14px] font-normal leading-[20px] space-x-2 gap-1'>
            By continuing, you have read and agree to our{' '}
            <a
              href={`${baseURL}/home/index.html#terms`}
              className='text-[var(--Headings, #474747)] text-[14px] font-normal leading-[20px] underline'
            >
              Terms and Conditions
            </a>
            <span>and</span>
            <a
              href={`${baseURL}/home/index.html#privacy`}
              className='text-[var(--Headings, #474747)] text-[14px] font-normal leading-[20px] underline'
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </div>
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </>
  )
}

export default Index
