import { Box, FormGroup, InputAdornment } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import { useEffect, useState } from 'react'
import { Drawer } from 'vaul'
import Arrow from '../../images/arrow_forward.png'
import CheckedIcon from '../../images/Checkbox.svg'
import UnCheckedIcon from '../../images/unChecked.png'
dayjs.extend(customParseFormat)

interface FilterComponentProps {
  schedules?: Set<string>
  selectedSchedules?: Set<string>
  activities?: Set<string>
  selectedActivities?: Set<string>
  selectedDate?: string
  testid?: string
  onSave: (scheduleSelections?: Set<string>, activitySelections?: Set<string>, dateSelection?: string) => void
}

const FilterComponent = (props: FilterComponentProps) => {
  const { schedules, activities, onSave, testid } = props
  const [selectedSchedules, setSelectedSchedules] = useState<Set<string> | undefined>(props?.selectedSchedules)
  const [selectedActivities, setSelectedActivities] = useState<Set<string> | undefined>(props?.selectedActivities)
  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(
    props?.selectedDate ? dayjs(props.selectedDate) : undefined,
  )
  const [snap, setSnap] = useState<string | number | null>(1.0)

  useEffect(() => {
    setSelectedSchedules(new Set(props?.selectedSchedules))
  }, [props.selectedSchedules])

  useEffect(() => {
    setSelectedActivities(new Set(props?.selectedActivities))
  }, [props.selectedActivities])

  useEffect(() => {
    setSelectedDate(props?.selectedDate ? dayjs(props.selectedDate) : undefined)
  }, [props.selectedDate])

  const handleSetSnap = (snapPoint: string | number | null) => {
    setSnap(snapPoint)
  }

  const handleScheduleChange = (option: string) => {
    if (selectedSchedules) {
      const newSelections = new Set(selectedSchedules)
      selectedSchedules.has(option) ? newSelections.delete(option) : newSelections.add(option)
      setSelectedSchedules(newSelections)
    }
  }

  const handleActivityChange = (option: string) => {
    if (selectedActivities) {
      const newSelections = new Set(selectedActivities)
      selectedActivities.has(option) ? newSelections.delete(option) : newSelections.add(option)
      setSelectedActivities(newSelections)
    }
  }

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date ?? dayjs())
  }

  const handleClearAll = () => {
    setSelectedSchedules(selectedSchedules ? new Set() : undefined)
    setSelectedActivities(selectedActivities ? new Set() : undefined)
    setSelectedDate(selectedDate ? dayjs() : undefined)
  }

  const handleSave = () => {
    onSave(selectedSchedules, selectedActivities, selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : undefined)
  }

  return (
    <div data-vaul-drawer-wrapper=''>
      <Drawer.Root
        snapPoints={[1.0]}
        activeSnapPoint={snap}
        disablePreventScroll={false}
        setActiveSnapPoint={handleSetSnap}
        open={true}
        modal={false}
        shouldScaleBackground
      >
        <Drawer.Portal>
          <Drawer.Overlay className='fixed inset-0 bg-black/40' />
          <Drawer.Content className='bg-zinc-100 flex flex-col rounded-t-[20px] h-[100%] mt-24 fixed bottom-0 left-0 right-0'>
            <div className='p-4 bg-white rounded-t-[20px] flex-1'>
              <div className='mx-auto w-16 h-1.5 rounded-full bg-zinc-300 mb-4' />
              <div className='max-w-md mx-auto'>
                <div data-testid={`${testid}-div`}>
                  <div className='px-2 pt-2'>
                    <p className='py-1.5 text-headings pb-4 text-md font-semibold'>Filters</p>
                    {selectedDate && (
                      <>
                        <p className='py-1.5 text-headings text-md font-medium'>Date</p>
                        <DatePicker
                          value={selectedDate}
                          formatDensity='spacious'
                          onChange={handleDateChange}
                          slotProps={{
                            textField: {
                              size: 'small',
                              autoFocus: true,
                              fullWidth: true,
                              sx: {
                                '& .MuiInputBase-root': {
                                  borderRadius: '6px',
                                },
                                '& .MuiInputBase-input': {
                                  color: '#68727D',
                                  padding: '12px 16px',
                                },
                              },
                              inputProps: {
                                readOnly: true,
                                'data-testid': 'calender-testid',
                              },
                              InputProps: {
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <div style={{ cursor: 'pointer' }}>
                                      <svg
                                        width='20'
                                        height='20'
                                        viewBox='0 0 20 20'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <g id='calendar_today'>
                                          <path
                                            id='Vector'
                                            d='M16.667 2.50065H15.8337V0.833984H14.167V2.50065H5.83366V0.833984H4.16699V2.50065H3.33366C2.41699 2.50065 1.66699 3.25065 1.66699 4.16732V17.5006C1.66699 18.4173 2.41699 19.1673 3.33366 19.1673H16.667C17.5837 19.1673 18.3337 18.4173 18.3337 17.5006V4.16732C18.3337 3.25065 17.5837 2.50065 16.667 2.50065ZM16.667 17.5006H3.33366V8.33398H16.667V17.5006ZM16.667 6.66732H3.33366V4.16732H16.667V6.66732Z'
                                            fill='#A3A3A3'
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                  </InputAdornment>
                                ),
                              },
                            },
                          }}
                        />
                      </>
                    )}
                    {schedules && schedules.size > 1 && (
                      <>
                        <p className='py-2 text-headings text-md font-medium pt-5'>Select School Schedule</p>
                        <FormGroup>
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4, padding: '5px 20px' }}>
                            {Array.from(schedules).map((option: string) => (
                              <Box key={option} sx={{ flexBasis: '40%' }}>
                                <div
                                  className='flex items-center cursor-pointer'
                                  onClick={() => handleScheduleChange(option)}
                                >
                                  <img
                                    src={
                                      selectedSchedules && selectedSchedules.has(option) ? CheckedIcon : UnCheckedIcon
                                    }
                                    alt={option}
                                    style={{ marginRight: '10px' }}
                                  />
                                  <span className='text-sm'>{option}</span>
                                </div>
                              </Box>
                            ))}
                          </Box>
                        </FormGroup>
                      </>
                    )}
                    {activities && activities.size > 0 && (
                      <>
                        <p className='py-2 text-headings text-md font-medium mt-4'>Activities</p>
                        <FormGroup>
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 4,
                              padding: '5px 20px',
                              height: '510px',
                              overflow: 'scroll',
                            }}
                          >
                            {Array.from(activities).map((v: string) => (
                              <Box key={v} sx={{ flexBasis: '40%' }}>
                                <div
                                  className='flex items-center cursor-pointer'
                                  onClick={() => handleActivityChange(v)}
                                >
                                  <img
                                    src={selectedActivities && selectedActivities.has(v) ? CheckedIcon : UnCheckedIcon}
                                    alt={v}
                                    style={{ marginRight: '10px' }}
                                  />
                                  <span className='text-sm'>{v}</span>
                                </div>
                              </Box>
                            ))}
                          </Box>
                        </FormGroup>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='fixed bottom-0 left-0 right-0 bg-[#fff] pt-3'>
              <div className='border-b border-gray-200'></div>
              <div className='pt-5 pb-10 px-5'>
                <div className='flex justify-around gap-3'>
                  <button onClick={handleClearAll} className='w-full py-3 bg-btn-grey text-gray-400 rounded-lg '>
                    Clear All
                  </button>
                  <button
                    onClick={handleSave}
                    className='w-full px-4 py-1.5 bg-custom-yellow text-headings rounded-lg flex items-center justify-center '
                  >
                    Save &nbsp;
                    <img src={Arrow} alt='' />
                  </button>
                </div>
              </div>
            </div>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    </div>
  )
}

export default FilterComponent
