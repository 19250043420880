import Tooltip from '@mui/material/Tooltip'
import AvatarComponent from 'components/avatar'
import DialogComponent from 'components/dialog'
import { useState } from 'react'
import ActivitiesIcon from '../../images/activities_icon.svg'
import CommentIcon from '../../images/comments_icon.svg'
import CopyIcon from '../../images/copy_icon.svg'
import ScheduleIcon from '../../images/schedule.svg'
import TimeIcon from '../../images/time_icon.png'
import UserIcon from '../../images/user_icon.svg'
import LineIcon from '../../images/vertical_bar_icon.png'
import './index.css'

interface DialogUserComponentProps {
  userName: string
  userRelationship: string
  contact: string
  distance: string
  scheduleName?: string
  seatsAvailable?: number
  time?: string
  activities?: string[]
  comments?: string
  onClick: () => Promise<void>
  data_testid?: string
}

const DialogUserComponent = (props: DialogUserComponentProps): JSX.Element => {
  const {
    data_testid,
    userName,
    userRelationship,
    contact,
    distance,
    scheduleName,
    seatsAvailable,
    time,
    activities,
    comments,
    onClick,
  } = props ?? {}
  const [show, setShow] = useState(false)
  const [tooltipText, setTooltipText] = useState('Copy to clipboard')
  const activityList = Array.isArray(activities) ? activities : []
  const isSeatsAvailable: boolean = seatsAvailable != null && seatsAvailable > 0

  // handlers
  const handleCopy = () => {
    navigator.clipboard.writeText(contact).then(
      () => {
        setTooltipText('Copied!')
        setTimeout(() => {
          setTooltipText('Copy to clipboard')
        }, 3000) // Reset the tooltip after 2 seconds
      },
      () => {
        setTooltipText('Failed to copy')
        setTimeout(() => {
          setTooltipText('Copy to clipboard')
        }, 3000) // Reset the tooltip after 2 seconds
      },
    )
  }
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // elements
  const body: JSX.Element = (
    <>
      <div>
        <div className='flex items-center gap-2 font-medium pb-3'>
          <img src={UserIcon} alt='' /> Basic Info
        </div>
      </div>
      <div className='flex items-center gap-5 flex-1'>
        <div className='flex items-center gap-3 flex-1'>
          <div className='flex w-10 h-10 p-1.5 justify-center items-center rounded-full bg-[#F7F7F8]'>
            <AvatarComponent name={userName}></AvatarComponent>
          </div>
          <div className='flex flex-col'>
            <div className='flex items-center gap-1'>
              <span className='text-[#474747] text-sm font-medium'>{userName}</span>
              <span className='w-[1px] bg-gray-300 h-[20px]' />
              <span className='text-[#797979] text-xs font-medium'>{userRelationship}</span>
            </div>
            <span className='text-[#FF7900] text-sm font-normal'>{distance} away</span>
          </div>
        </div>
        {isSeatsAvailable && (
          <div className='flex items-center '>
            <div className='text-xs inline-block bg-custom-yellow font-normal text-headings px-2 py-1 rounded-2xl'>
              {seatsAvailable} seats available
            </div>
          </div>
        )}
      </div>
      <div className='flex items-center gap-2 mt-4 '>
        {time && (
          <div className='flex items-center gap-1'>
            <img src={TimeIcon} alt='' />
            <span className='text-headings text-sm font-normal'>{time}</span>
          </div>
        )}
        {time && scheduleName && <img src={LineIcon} alt='' />}
        {scheduleName && (
          <>
            <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
              <path
                d='M15 2.25H14.25V0.75H12.75V2.25H5.25V0.75H3.75V2.25H3C2.175 2.25 1.5 2.925 1.5 3.75V15.75C1.5 16.575 2.175 17.25 3 17.25H15C15.825 17.25 16.5 16.575 16.5 15.75V3.75C16.5 2.925 15.825 2.25 15 2.25ZM15 15.75H3V7.5H15V15.75ZM15 6H3V3.75H15V6Z'
                fill='#E5C265'
              />
            </svg>{' '}
            <span className='text-headings text-sm font-normal'>{scheduleName}</span>
          </>
        )}
      </div>
      {comments && (
        <div className='pt-8'>
          <div className='flex items-center gap-2'>
            <img src={CommentIcon} alt='' /> Comments
          </div>
          <div className='text-sm pt-2 text-disable'>{comments}</div>
        </div>
      )}
      {activities && (
        <div className='pt-8'>
          <div className='flex items-center gap-2'>
            <img src={ActivitiesIcon} alt='' /> Activities
          </div>
          <div className='text-sm pt-2 text-disable' data-testid='row-testid'>
            <div className={`flex flex-wrap items-center gap-1 text-sub-headings font-normal text-sm`}>
              {activityList.map((activity: any, index: number) => (
                <div key={index} className='flex items-center gap-1'>
                  <div>{activity}</div>
                  {index < activityList.length - 1 && (
                    <div style={{ background: '#666', width: '5px', height: '5px', borderRadius: '50%' }}></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {false && (
        <div className='pt-8'>
          <div className='flex items-center gap-2'>
            <img src={ScheduleIcon} alt='' /> Schedule
          </div>
          <div className='text-sm text-disable p-4 bg-gray-500 flex gap-2.5 mt-3 rounded-xl'>
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
              <path
                d='M9.1665 5.83268H10.8332V7.49935H9.1665V5.83268ZM9.1665 9.16602H10.8332V14.166H9.1665V9.16602ZM9.99984 1.66602C5.39984 1.66602 1.6665 5.39935 1.6665 9.99935C1.6665 14.5993 5.39984 18.3327 9.99984 18.3327C14.5998 18.3327 18.3332 14.5993 18.3332 9.99935C18.3332 5.39935 14.5998 1.66602 9.99984 1.66602ZM9.99984 16.666C6.32484 16.666 3.33317 13.6743 3.33317 9.99935C3.33317 6.32435 6.32484 3.33268 9.99984 3.33268C13.6748 3.33268 16.6665 6.32435 16.6665 9.99935C16.6665 13.6743 13.6748 16.666 9.99984 16.666Z'
                fill='#666666'
              />
            </svg>
            <div className='text-headings font-medium'>Default school pick up time is 2:30 PM</div>
          </div>
        </div>
      )}
    </>
  )
  const action: JSX.Element = (
    <>
      <div className='flex items-center gap-2' style={{ textAlign: 'left' }}>
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
          <path
            d='M6.54 5C6.6 5.89 6.75 6.76 6.99 7.59L5.79 8.79C5.38 7.59 5.12 6.32 5.03 5H6.54ZM16.4 17.02C17.25 17.26 18.12 17.41 19 17.47V18.96C17.68 18.87 16.41 18.61 15.2 18.21L16.4 17.02ZM7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.51C21 15.96 20.55 15.51 20 15.51C18.76 15.51 17.55 15.31 16.43 14.94C16.33 14.9 16.22 14.89 16.12 14.89C15.86 14.89 15.61 14.99 15.41 15.18L13.21 17.38C10.38 15.93 8.06 13.62 6.62 10.79L8.82 8.59C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3Z'
            fill='#474747'
          />
        </svg>{' '}
        <span className='font-medium text-md text-headings'>Contact Info</span>
      </div>
      <div className='flex gap-3'>
        <div className='flex flex-grow mt-2 items-center gap-2 justify-center text-gray-400 bg-light-yellow border border-custom-yellow py-2 rounded-lg'>
          <Tooltip title={tooltipText} arrow>
            <img src={CopyIcon} alt='' onClick={handleCopy} />
          </Tooltip>
          {contact}
        </div>
        {onClick && (
          <div
            className='flex mt-2 items-center gap-2 justify-center text-gray-400 bg-light-yellow border border-custom-yellow py-2 px-3 rounded-lg cursor-pointer'
            onClick={onClick}
          >
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
              <path
                d='M11.6665 11.666V14.166H4.99984V8.33268C4.99984 6.49102 6.4915 4.99935 8.33317 4.99935C9.0415 4.99935 9.69984 5.21602 10.2332 5.59935L11.4248 4.40768C10.8915 3.98268 10.2665 3.67435 9.58317 3.49935V2.91602C9.58317 2.22435 9.02484 1.66602 8.33317 1.66602C7.6415 1.66602 7.08317 2.22435 7.08317 2.91602V3.49935C4.92484 4.04935 3.33317 6.00768 3.33317 8.33268V14.166H1.6665V15.8327H14.9998V14.166H13.3332V11.666H11.6665ZM8.33317 18.3327C9.24984 18.3327 9.99984 17.5827 9.99984 16.666H6.6665C6.6665 17.5827 7.4165 18.3327 8.33317 18.3327ZM18.3332 6.66602H15.8332V4.16602H14.1665V6.66602H11.6665V8.33268H14.1665V10.8327H15.8332V8.33268H18.3332V6.66602Z'
                fill='#666666'
              />
            </svg>
          </div>
        )}
      </div>
    </>
  )

  return (
    <>
      <button
        className='flex w-10 h-10 justify-center items-center rounded-lg bg-[#F0E977]'
        aria-label='More options'
        onClick={handleShow}
        data-testid={data_testid}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          role='img'
          aria-label='More options'
        >
          <path
            d='M9.99992 3.33301L8.82492 4.50801L13.4749 9.16634H3.33325V10.833H13.4749L8.82492 15.4913L9.99992 16.6663L16.6666 9.99967L9.99992 3.33301Z'
            fill='#474747'
          />
        </svg>
      </button>
      {show && <DialogComponent title={userName} body={body} action={action} onCancel={handleClose} />}
    </>
  )
}

export default DialogUserComponent
