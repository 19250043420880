import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { PaperFlowComponent } from 'components/PaperFlowComponent'
import ButtonComponent from 'components/button'
import CenterComponent from 'components/center'
import SelectComponent from 'components/select'
import ToastComponent from 'components/toast'
import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { getSessionItem, setSessionItem } from 'utils/constants'
import { MessageProps } from 'utils/types'
import { isValidScheduleGroup } from 'utils/validations'
import './index.css'

function Index(): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: Schedule Groups
  const schoolScheduleGroupNames: string[] = getSessionItem('x-pool-ssgn') || ''

  // states
  const [selectedScheduleGroup, setSelectedScheduleGroup] = useState<string>('')
  const [msg, setMsg] = useState<MessageProps>()

  // handlers
  const onClose = () => navigate('/')
  const onBack = () => navigate(-1)
  const onNext = async (): Promise<any> => {
    // Save schedule group for following steps
    setSessionItem('x-pool-sg', selectedScheduleGroup)
    navigate('/signup/createUser')
  }

  const isValid: boolean = isValidScheduleGroup(schoolScheduleGroupNames, selectedScheduleGroup, true)

  return (
    <CenterComponent onClose={onClose} onBack={onBack} spacing={2}>
      <PaperFlowComponent
        title={'Select School Schedule'}
        subtitle={`Your school has multiple start and dismissal times. Select your schedule.`}
      />
      <SelectComponent
        label='Select School Schedule'
        isRequired={true}
        isError={!isValidScheduleGroup(schoolScheduleGroupNames, selectedScheduleGroup)}
        options={schoolScheduleGroupNames} // Options from the fetched data
        value={selectedScheduleGroup}
        onChange={(v: string) => setSelectedScheduleGroup(v)}
      />
      <ButtonComponent
        text='Next'
        disable={!isValid}
        data_testid='next-btn-testid'
        endIcon={<ArrowForwardIcon />}
        onClick={() => onNext()}
      />
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </CenterComponent>
  )
}

export default Index
