import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import DashboardOutlined from '@mui/icons-material/DashboardOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import GridViewIcon from '@mui/icons-material/GridView'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LightModeIcon from '@mui/icons-material/LightMode'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import TextComponent from 'components/text'
import { Fragment, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { baseURL } from 'utils/constants'
import Logo from '../../images/cslogo.png'
import './index.css'

interface NavProps {
  icon?: JSX.Element
  item: string
  href: string
  itemProps?: { [key: string]: any }
}

interface AppBarProps extends MuiAppBarProps {
  isDrawerOpen?: boolean | null // tertiary state to distinguish between drawer open|closed|not_shown
}
const drawerOpenWidthXS: number = 140
const drawerOpenWidthUpSM: number = 210
const drawerCloseWidthXS: number = 50
const drawerCloseWidthUpSM: number = 60
const color: any = 'warning'

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'isDrawerOpen',
})<AppBarProps>(({ theme, isDrawerOpen }) => ({
  width: `calc(100% - ${isDrawerOpen === null ? 0 : drawerCloseWidthXS}px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(100% - ${isDrawerOpen === null ? 0 : drawerCloseWidthUpSM}px)`,
  },

  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(isDrawerOpen && {
    marginLeft: drawerOpenWidthXS,
    width: `calc(100% - ${drawerOpenWidthXS}px)`,
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerOpenWidthUpSM,
      width: `calc(100% - ${drawerOpenWidthUpSM}px)`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerOpenWidthXS,
    [theme.breakpoints.up('sm')]: {
      width: drawerOpenWidthUpSM,
    },

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: drawerCloseWidthXS,
      [theme.breakpoints.up('sm')]: {
        width: drawerCloseWidthUpSM,
      },
    }),
  },
}))

const Nav = (props: { brand: NavProps; links: NavProps[]; drawerLinks?: NavProps[][]; signOutUrl: string }) => {
  const { brand, links, drawerLinks, signOutUrl } = props ?? {}

  const theme = useTheme()
  const isUpSM: boolean = useMediaQuery(theme.breakpoints.up('sm'))
  const isShowAppBar: boolean = isUpSM // Show app bar on SM and above screens
  const isShowDrawer: boolean = Array.isArray(drawerLinks) ? true : false
  const [open, setOpen] = useState<boolean>(false)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <div className='flex'>
      {isShowAppBar && (
        <AppBar
          position='absolute'
          isDrawerOpen={isShowDrawer ? open : null}
          sx={{ display: 'block', background: '#f5f5f5' }}
        >
          <Toolbar
            sx={{
              pr: 0,
              // keep right padding when drawer closed
            }}
          >
            <Typography
              component='a'
              variant='h6'
              href={brand.href}
              color='inherit'
              noWrap
              sx={{ flexGrow: 1, textDecoration: 'none', mx: 4 }}
            >
              <img
                src={Logo}
                width='210'
                height='40'
                style={{ cursor: 'pointer', marginTop: '8px' }}
                alt='Carpool.School Logo and Brand'
              />
            </Typography>
            {!open && (
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {links.map((v, i) => (
                  <Button key={i} variant='text' color={color} href={v.href}>
                    {v.icon}
                    {window.location.pathname.endsWith(v.href) ? (
                      <TextComponent textDecoration='none'>{v.item}</TextComponent>
                    ) : (
                      <TextComponent>{v.item}</TextComponent>
                    )}
                  </Button>
                ))}
              </Box>
            )}
          </Toolbar>
        </AppBar>
      )}
      {isShowDrawer && (
        <Drawer data-testid='sidebar-nav' variant='permanent' open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingTop: 'env(safe-area-inset-top)',
              paddingBottom: 'env(safe-area-inset-bottom)',
            }}
          >
            {open ? (
              <>
                {isUpSM ? (
                  <Typography variant='subtitle1' sx={{ fontWeight: 500 }}>
                    Carpool.School
                  </Typography>
                ) : (
                  <Typography variant='caption' sx={{ fontWeight: 500 }}>
                    Carpool.School
                  </Typography>
                )}
                <IconButton onClick={toggleDrawer}>
                  <ChevronLeftOutlinedIcon />
                </IconButton>
              </>
            ) : (
              <IconButton edge='start' color='inherit' aria-label='open drawer' onClick={toggleDrawer}>
                <MenuOutlinedIcon color={color} />
              </IconButton>
            )}
          </Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <List
              component='nav'
              sx={{
                '& .MuiListItemButton-root:hover': {
                  bgcolor: '#fcf7de',
                  borderRadius: '10px',
                  '&, & .MuiListItemIcon-root': {
                    color: 'primary',
                  },
                },
                marginX: { xs: '8px', sm: '10px', md: '5px' },
              }}
            >
              <center>
                {drawerLinks?.map((va, i) => (
                  <Fragment key={i}>
                    {va.map((v, j) => (
                      <ListItemButton
                        key={j}
                        href={v.href}
                        disableGutters
                        selected={window.location.pathname.endsWith(v.href)}
                        {...v?.itemProps}
                        sx={{
                          '&.Mui-selected': {
                            backgroundColor: '#fcf7de',
                            borderRadius: '10px',
                          },
                          px: { xs: 0.7, sm: 0.7, md: 1.5 },
                          py: 1.1,
                          my: 0.5,
                          borderRadius: '10px',
                          '& .MuiListItemIcon-root': {
                            minWidth: { xs: 40, sm: 50 }, // Adjust icon size for small screens
                          },
                          '& svg': {
                            fontSize: { xs: '20px', sm: '24px' }, // Adjust svg icon size for small screens
                          },
                        }}
                      >
                        {v.icon && (
                          <Tooltip title={v.item}>
                            <ListItemIcon>{v.icon}</ListItemIcon>
                          </Tooltip>
                        )}
                        <ListItemText
                          primary={v.item}
                          primaryTypographyProps={{
                            fontSize: { xs: '12px', sm: '16px' }, // Adjust font size for small screens
                          }}
                        />
                      </ListItemButton>
                    ))}
                  </Fragment>
                ))}
              </center>
            </List>
          </Box>
          {signOutUrl && (
            <List
              component='nav'
              sx={{
                marginX: '5px',
                '& .MuiListItemButton-root:hover': {
                  bgcolor: '#fcf7de',
                  borderRadius: '10px',
                  '&, & .MuiListItemIcon-root': {
                    color: 'primary',
                  },
                },
              }}
            >
              <center>
                <ListItemButton
                  href={signOutUrl}
                  disableGutters
                  sx={{
                    borderRadius: '10px',
                    px: { xs: 0.7, sm: 0.7, md: 1.5 },
                    py: 1.1,
                    my: 0.5,
                    '& .MuiListItemIcon-root': {
                      minWidth: { xs: 40, sm: 50 }, // Adjust icon size for small screens
                    },
                    '& svg': {
                      fontSize: { xs: '20px', sm: '24px' }, // Adjust svg icon size for small screens
                    },
                    marginX: { xs: '8px', sm: '10px', md: '5px' },
                  }}
                >
                  <Tooltip title='Sign out'>
                    <ListItemIcon>
                      <LogoutOutlinedIcon color='warning' />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary='Sign out'
                    primaryTypographyProps={{
                      fontSize: { xs: '12px', sm: '16px' }, // Adjust font size for small screens
                    }}
                  />
                </ListItemButton>
              </center>
            </List>
          )}
        </Drawer>
      )}
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          paddingTop: 'env(safe-area-inset-top)',
          paddingBottom: 'env(safe-area-inset-bottom)',
        }}
      >
        <Container
          sx={{
            // Provide a margin to account for appbar height
            ...(isShowAppBar && { mt: 5, mb: 5, p: 4 }),
          }}
        >
          <Outlet />
        </Container>
      </Box>
    </div>
  )
}

export const WithoutNav = (): JSX.Element => <Outlet />

export const WithNav = (props: any) => {
  const { isSignedIn, students } = props ?? {}
  const isStudents: boolean = Array.isArray(students) && students.length ? true : false
  // brand
  const navBrand: NavProps = { item: 'Carpool.School', href: '/' }

  // links
  const links: NavProps[] = []
  if (!isSignedIn) links.push({ item: 'Sign up', href: '/signup' }, { item: 'Sign in', href: '/signin' })
  else if (!isStudents) links.push({ item: 'Dashboard', href: '/' })
  else links.push({ item: 'Dashboard', href: '/' }, { item: 'Sign out', href: '/signout' })

  return <Nav brand={navBrand} links={links} signOutUrl={isSignedIn ? '/signout' : ''} />
}

export const WithFamilyNav = (props: any) => {
  const { isSignedIn, students, currentStudentId } = props ?? {}
  const isStudents: boolean = Array.isArray(students) && students.length ? true : false
  // brand
  const navBrand: NavProps = { item: 'Carpool.School', href: '/' }

  // links
  const links: NavProps[] = []
  if (!isSignedIn) links.push({ item: 'Sign up', href: '/signup' }, { item: 'Sign in', href: '/signin' })
  else if (!isStudents) links.push({ item: 'Dashboard', href: '/' })
  else links.push({ item: 'Dashboard', href: '/' }, { item: 'Sign out', href: '/signout' })

  // drawer links
  const drawerLinks: NavProps[][] = []
  if (!isSignedIn)
    drawerLinks.push([
      { icon: <PersonAddOutlinedIcon color={color} />, item: 'Sign up', href: '/signup' },
      { icon: <LoginOutlinedIcon color={color} />, item: 'Sign in', href: '/signin' },
    ])
  else if (!isStudents)
    drawerLinks.push([
      { icon: <DashboardOutlined color={color} />, item: 'Dashboard', href: '/' },
      { icon: <ManageAccountsOutlinedIcon color={color} />, item: 'Profile', href: '/profile' },
      { icon: <SettingsOutlinedIcon color={color} />, item: 'Settings', href: '/settings' },
    ])
  else {
    const query: string = currentStudentId ? `?id=${currentStudentId}` : ''
    drawerLinks.push([
      { icon: <DashboardOutlined color={color} />, item: 'Dashboard', href: `/students${query}` },
      { icon: <LightModeOutlinedIcon color={color} />, item: 'Morning', href: `/morning${query}` },
      { icon: <LightModeIcon color={color} />, item: 'Afternoon', href: `/afternoon${query}` },
      { icon: <SchoolOutlinedIcon color={color} />, item: 'Schools', href: `/change${query}` },
      { icon: <ManageAccountsOutlinedIcon color={color} />, item: 'Profile', href: '/profile' },
      { icon: <SettingsOutlinedIcon color={color} />, item: 'Settings', href: '/settings' },
    ])
  }

  // Open Guides, Support and Terms in New Tab
  const itemProps = { target: '_blank', rel: 'noopener noreferrer' }
  drawerLinks.push([
    {
      icon: <InfoOutlinedIcon color={color} />,
      item: 'Guides',
      href: `${baseURL}/home/index.html#quickstart`,
      itemProps,
    },
    { icon: <HelpOutlineIcon color={color} />, item: 'Support', href: `${baseURL}/home/index.html#support`, itemProps },
    {
      icon: <DescriptionOutlinedIcon color={color} />,
      item: 'Legal',
      href: `${baseURL}/home/index.html#legal`,
      itemProps,
    },
  ])

  return <Nav brand={navBrand} links={links} drawerLinks={drawerLinks} signOutUrl={isSignedIn ? '/signout' : ''} />
}

export const WithAdminNav = (props: any) => {
  const { isSignedIn, isActive } = props ?? {}

  // brand
  const navBrand: NavProps = { item: 'Carpool.School', href: !isSignedIn ? '/' : '/admin/settings' }

  // links
  const links: NavProps[] = []
  if (!isSignedIn) links.push({ item: 'Admin', href: '/admin/signin' })
  else if (!isActive) links.push({ item: 'Sign out', href: '/admin/signout' })
  else links.push({ item: 'Dashboard', href: '/admin/dashboard' }, { item: 'Sign out', href: '/admin/signout' })

  // drawerLinks
  const drawerLinks: NavProps[][] = []
  if (!isSignedIn)
    drawerLinks.push([{ icon: <LoginOutlinedIcon color={color} />, item: 'Admin', href: '/admin/signin' }])
  else if (!isActive)
    drawerLinks.push([{ icon: <SettingsOutlinedIcon color={color} />, item: 'Settings', href: '/admin/settings' }])
  else
    drawerLinks.push([
      { icon: <GridViewIcon color={color} />, item: 'Dashboard', href: '/admin/dashboard' },
      { icon: <PeopleOutlinedIcon color={color} />, item: 'Families', href: '/admin/users' },
      { icon: <SettingsOutlinedIcon color={color} />, item: 'Settings', href: '/admin/settings' },
    ])

  // Open Guides, Support and Terms in New Tab
  const itemProps = { target: '_blank', rel: 'noopener noreferrer' }
  drawerLinks.push([
    {
      icon: <InfoOutlinedIcon color={color} />,
      item: 'Guides',
      href: `${baseURL}/home/index.html#quickstart`,
      itemProps,
    },
    { icon: <HelpOutlineIcon color={color} />, item: 'Support', href: `${baseURL}/home/index.html#support`, itemProps },
    {
      icon: <DescriptionOutlinedIcon color={color} />,
      item: 'Legal',
      href: `${baseURL}/home/index.html#legal`,
      itemProps,
    },
  ])

  return (
    <Nav brand={navBrand} links={links} drawerLinks={drawerLinks} signOutUrl={isSignedIn ? '/admin/signout' : ''} />
  )
}
