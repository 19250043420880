import { Box, Dialog, DialogTitle, LinearProgress, Radio } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import BackComponent from 'components/back'
import ButtonMobileComponent from 'components/button-mobile'
import ModalConfirmRemoveComponent from 'components/modal-confirm-remove'
import SelectMobileComponent from 'components/select-mobile'
import CircularProgressWithLabel from 'components/table/CircularProgressLabel'
import ToastComponent from 'components/toast'
import React, { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { titleCaseSchoolNameForUI } from 'utils/constants'
import { deleteStudent, updateStudentSettings } from 'utils/students'
import { MessageProps, StudentProps } from 'utils/types'
import Arrow from '../../images/arrow_forward.png'
import './index.css'

const CustomRadio = styled(Radio)(({ theme }) => ({
  marginLeft: '4px',
  color: '#A3A3A3', // Default color when not checked
  width: '16px',
  '&.Mui-checked': {
    color: '#E5C265', // Color when checked
  },
}))

interface ChangeMobileComponentProps {
  studentIdFromQuery: string
  students: StudentProps[]
  onSelect: (ssi: string, cb?: () => void) => void
  onRemove: (ssi: string, cb?: () => void) => void
}

function ChangeMobileComponent(props: ChangeMobileComponentProps): JSX.Element {
  const theme = useTheme()
  const navigate: NavigateFunction = useNavigate()

  // Inputs: Students, Selected Student Id and Callback
  const { studentIdFromQuery, students, onSelect, onRemove } = props ?? {}

  // states
  const [selectedStudentId, setSelectedStudentId] = useState<string>()
  const [selectedSchoolName, setSelectedSchoolName] = useState<string>()
  const [selectedSchedule, setSelectedSchedule] = useState<string>()
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = React.useState<string>('')

  // hooks
  useEffect(() => {
    const student: StudentProps | undefined = students?.find((s: StudentProps) => s.studentId === studentIdFromQuery)
    setSelectedStudentId(studentIdFromQuery)
    setSelectedSchoolName(student?.schoolName ?? '')
    setSelectedSchedule(student?.scheduleGroupName ?? '')
  }, [studentIdFromQuery, students])

  // handlers
  // Handle change school
  const handleSelectSchool = async (sn: string, sid: string, sgn: string) => {
    setSelectedSchoolName(sn)
    setSelectedStudentId(sid)
    setSelectedSchedule(sgn)
  }
  // Handle delete school
  const handleRemoveSchool = async (schoolName: string, studentId: string) => {
    setIsLoading('Removing school...')
    const { msg, error } = await deleteStudent(schoolName, studentId)
    if (error) setMsg(msg)
    else {
      // change the selected school if it was deleted
      if (studentId === studentIdFromQuery) await onRemove(studentId, () => navigate(`/change`))
      else await onRemove(studentId)
    }
    setIsLoading('')
  }
  // Cancel button handler
  const handleCancel = async () => navigate(`/students?id=${studentIdFromQuery}`)
  // Save button handler
  const handleSave = async () => {
    if (selectedStudentId && selectedSchoolName && selectedSchedule) {
      const { msg, error } = await updateStudentSettings(
        selectedSchoolName,
        selectedStudentId,
        undefined,
        undefined,
        undefined,
        selectedSchedule,
      )
      if (error) setMsg(msg)
      else {
        setMsg({ style: 'success', text: 'School updated successfully.' })
        setTimeout(() => setMsg(undefined), 4000)
        await onSelect(selectedStudentId, () => navigate(`/change?id=${selectedStudentId}`))
      }
    }
  }

  return (
    <>
      <BackComponent text='Change School' onClick={handleCancel} />

      <div className='font-figtree px-5 font-medium pt-4'>
        <ButtonMobileComponent
          text={`New School`}
          width={'full'}
          href='/add'
          icon={
            <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
              <path
                d='M16.3334 10.8327H11.3334V15.8327H9.66669V10.8327H4.66669V9.16602H9.66669V4.16602H11.3334V9.16602H16.3334V10.8327Z'
                fill='#474747'
              />
            </svg>
          }
        />
      </div>
      <div className='font-figtree px-5 pt-6'>
        {students && students.length > 0 && (
          <>
            <p className='text-gray-400 text-base font-semibold pb-3'>Select your school</p>
            {students.map((s: StudentProps, i: number) => {
              const { schoolName, schoolStats, schoolScheduleGroups, studentId, scheduleGroupName } = s
              const schoolScheduleGroupNames: string[] =
                schoolScheduleGroups?.map((group: any) => group.schoolScheduleGroupName) ?? []
              const isSelected: boolean = selectedStudentId === studentId
              const maxUsers = Math.max(schoolStats.pickupUsers, schoolStats.dropoffUsers)
              const isShowMaxUsers: boolean = maxUsers > 5 ? true : false

              return (
                <Box
                  key={i}
                  sx={{
                    border: `1px solid ${isSelected ? '#F0E977' : '#E5E5E5'}`,
                    padding: 1,
                    borderRadius: '12px',
                    width: '100%',
                    mb: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <div className='flex items-center mr-1'>
                      <CustomRadio
                        size='small'
                        checked={isSelected}
                        onChange={() => handleSelectSchool(schoolName, studentId, scheduleGroupName)}
                        value={studentId}
                      />
                      <div className={`${isSelected ? 'text-headings' : 'text-gray-400'} ml-2 text-sm font-medium`}>
                        {titleCaseSchoolNameForUI(schoolName)}
                      </div>
                    </div>
                    <div className='flex items-center gap-2'>
                      {isShowMaxUsers && (
                        <div className='text-[12px] bg-light-yellow  py-1 px-2 rounded-xl'>{maxUsers} Families</div>
                      )}
                      <ModalConfirmRemoveComponent
                        data_testid='DeleteOutlinedIcon'
                        title='Remove Registered School?'
                        body={`You will no longer see ${titleCaseSchoolNameForUI(schoolName)}`}
                        onClick={() => handleRemoveSchool(schoolName, studentId)}
                      />
                    </div>
                  </Box>
                  {isSelected && schoolScheduleGroupNames.length > 1 && (
                    <div className='pt-2.5'>
                      <SelectMobileComponent
                        key='1c'
                        label='Select School Schedule'
                        isRequired={true}
                        value={selectedSchedule} // Use the state value
                        options={schoolScheduleGroupNames} // Options from the fetched data
                        onChange={setSelectedSchedule} // Update state when changed
                      />
                    </div>
                  )}
                </Box>
              )
            })}
            <div className='fixed bottom-0 left-0 right-0 bg-[#fff] pt-3'>
              <div className='border-b border-gray-200'></div>
              <div className='pt-4 pb-7 px-5'>
                <div className='flex justify-around gap-3'>
                  <button onClick={handleCancel} className='w-full py-3 bg-btn-grey text-gray-400 rounded-lg '>
                    Cancel
                  </button>
                  <button
                    onClick={handleSave}
                    className='w-full px-4 py-1.5 bg-custom-yellow text-headings rounded-lg flex items-center justify-center '
                  >
                    Save &nbsp;
                    <img src={Arrow} alt='' />
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/***************** Display progress *****************/}
      {isLoading && (
        <Dialog open={true}>
          <DialogTitle>{isLoading}</DialogTitle>
          <CircularProgressWithLabel />
        </Dialog>
      )}

      {/***************** Display messages *****************/}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </>
  )
}

interface Props {
  students: StudentProps[]
  processQuery: (id: string) => string
  onUpdate: (cb?: () => void) => void
  onSelect: (ssi: string, cb?: () => void) => void
  onRemove: (ssi: string, cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()

  // Inputs: Selected Student Id, and Callback
  const { students, processQuery, onSelect, onRemove } = props

  // We should never call this component without student id query search parameters, but this is just to be safe
  const studentIdFromQuery = searchParams.get('id') ?? ''
  useEffect(() => {
    setSearchParams({ id: processQuery(studentIdFromQuery) })
  }, [studentIdFromQuery, processQuery, setSearchParams])

  // Check if queried student exists
  const student: StudentProps | undefined = students?.find((s: StudentProps) => s.studentId === studentIdFromQuery)

  if (!student && students && students?.length > 0) return <LinearProgress />
  else
    return (
      <ChangeMobileComponent
        studentIdFromQuery={studentIdFromQuery}
        students={students}
        onSelect={onSelect}
        onRemove={onRemove}
      />
    )
}

export default Index
