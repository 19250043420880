import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import './index.css'
import Typography from '@mui/material/Typography'

const CheckboxComponent = (props: any): JSX.Element => {
  const { disable, isChecked, label, onChange, justifyContent, color } = props ?? {}
  const children: JSX.Element = (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox disabled={disable ? true : false} checked={isChecked} onChange={onChange} color={color} />}
        label={<Typography variant='body2'>{label ?? ''}</Typography>}
        sx={{ display: 'flex', justifyContent: justifyContent ?? 'center', zIndex: 0, position: 'relative' }}
      />
    </FormGroup>
  )
  return children
}

export default CheckboxComponent
