import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Dialog, DialogTitle, LinearProgress } from '@mui/material'
import Grid from '@mui/material/Grid'
import ButtonComponent from 'components/button'
import { CalendarComponent } from 'components/calendar'
import CenterComponent from 'components/center'
import CheckboxComponent from 'components/checkbox'
import InputComponent from 'components/input'
import ModalConfirmRemoveComponent from 'components/modal-confirm-remove'
import PaperComponent from 'components/paper'
import PlacesAutocompleteComponent from 'components/places-autocomplete'
import SelectComponent from 'components/select'
import CircularProgressWithLabel from 'components/table/CircularProgressLabel'
import TagComponent from 'components/tag'
import ToastComponent from 'components/toast'
import { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { getFinalTime, getFullDate, getTimeNumber, getTimeString, getTimeStringFromNumber } from 'utils/constants'
import { geocode } from 'utils/map'
import { updateStudentAfternoonScheduleSettings, updateStudentAfternoonSettings } from 'utils/students'
import {
  CalendarEventProps,
  CalendarProps,
  CalendarState,
  DateTimeProps,
  LocationProps,
  MessageProps,
  ScheduleGroupProps,
  StudentProps,
} from 'utils/types'
import { isValidComments } from 'utils/validations'
import './index.css'

interface AfternoonComponentProps {
  student: StudentProps
  date?: string | null
  onUpdate: (cb?: () => void) => void
}

const AfternoonComponent = (props: AfternoonComponentProps) => {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: School Name, Location, Schedule End Time, Student Drop off Location, Activities, Schedule and onUpdate Callbck
  const { student, onUpdate } = props
  const {
    schoolName,
    schoolLocation,
    schoolScheduleGroups,
    studentId,
    scheduleGroupName,
    dropoffLocation,
    dropoffTimes,
  } = student
  const isNeedPMPool: boolean = dropoffLocation && dropoffLocation?.lat && dropoffLocation?.lng ? true : false
  const schoolEndTime: number =
    schoolScheduleGroups?.find((v: ScheduleGroupProps) => v?.schoolScheduleGroupName === scheduleGroupName)
      ?.schoolScheduleGroupEndTime ?? 0 // Initialize selected schedule dismissal time.
  const schoolActivities = student?.schoolActivities?.sort() ?? []
  const { lat, lng } = schoolLocation
  const bounds = lat && lng ? [lng - 0.7, lat - 0.7, lng + 0.7, lat + 0.7] : undefined

  // states
  const [studentDropoffLocation, setStudentDropoffLocation] = useState<LocationProps>({
    address: dropoffLocation?.address || '',
  })
  const [dropoffSeatsAvailable, setDropoffSeatsAvailable] = useState<number | null>(
    student?.dropoffSeatsAvailable ?? null,
  )
  const [dropoffComments, setDropoffComments] = useState<string>(student?.dropoffComments ?? '')
  const [studentActivities, setStudentActivities] = useState<string[]>(
    student?.studentActivities?.filter((v: string) => schoolActivities?.includes(v)) ?? [],
  )
  const [dropoffDateTime, setDropoffDateTime] = useState<DateTimeProps>(dropoffTimes ?? {})
  const [dropoffTimeDefault, setDropoffTimeDefault] = useState<number | null | undefined>(student?.dropoffTimeDefault)
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')

  // handlers
  const onSubmit = async (): Promise<any> => {
    let dropoffLocation: LocationProps = studentDropoffLocation
    if (dropoffLocation.address && (!dropoffLocation?.lat || !dropoffLocation?.lng)) {
      dropoffLocation = await geocode(dropoffLocation.address)
      if (!dropoffLocation?.lat || !dropoffLocation?.lng) {
        setMsg({
          style: 'error',
          text: 'We could not locate this address. Verify and re-enter address.',
        })
        setIsLoading('')
        return
      }
    }
    const { msg, error } = await updateStudentAfternoonSettings(
      schoolName,
      studentId,
      dropoffLocation,
      dropoffSeatsAvailable,
      dropoffComments,
      studentActivities,
      dropoffTimeDefault,
    )
    if (error) setMsg(msg)
    else onUpdate(() => navigate('/'))
    setStudentDropoffLocation(dropoffLocation)
    setIsLoading('')
  }
  const events: CalendarEventProps[] = []
  const toCalendarEvents = (dateTime: DateTimeProps, defaultTime?: number | null) => {
    const startDate: string = new Date().toISOString() // Initialize start to current date.
    const endDate: string = new Date(new Date().getFullYear() + 1, 11, 31).toISOString() // Initialize end to end of next year.
    let loop = new Date(new Date(startDate))
    do {
      const d: string = getFullDate(loop)
      const t: number = getFinalTime(schoolEndTime, dateTime[d], defaultTime)
      const event: CalendarEventProps | undefined = {
        from: new Date(`${d}T${getTimeStringFromNumber(t === -1 ? schoolEndTime : t)}`),
        eventState: t === -1 ? CalendarState.DISABLE : d in dateTime ? CalendarState.ENABLE : undefined,
      }
      const isWeekday = (): boolean => event.from.getDay() !== 0 && event.from.getDay() !== 6
      if (isWeekday()) {
        if (event) events.push(event)
      }
      loop = new Date(loop.setDate(loop.getDate() + 1))
    } while (loop <= new Date(endDate))
  }
  toCalendarEvents(dropoffDateTime ?? {}, dropoffTimeDefault)

  // Function to handle delete submit
  const handleDelSubmit = async () => {
    setIsLoading('Deleting afternoon carpool request')
    const dropoffLocation: LocationProps = { address: '' }
    const { msg, error } = await updateStudentAfternoonSettings(schoolName, studentId, dropoffLocation)
    if (error) setMsg(msg)
    else onUpdate(() => navigate('/'))
    setStudentDropoffLocation(dropoffLocation)
    setIsLoading('')
  }

  const isValidAddress: boolean = studentDropoffLocation.address ? true : false
  const isValid: boolean = isValidAddress && isValidComments(dropoffComments)

  return (
    <CenterComponent marginTop={0}>
      <PaperComponent title={'Afternoon Carpool'}>
        {/* Select address */}
        <PaperComponent subtitle={'Dropoff Location'}>
          <Grid container>
            <Grid item xs>
              <PlacesAutocompleteComponent
                isRequired={true}
                label='Select Address / Landmark'
                defaultValue={studentDropoffLocation.address}
                dataTestid='dropoff-afternoon-testid'
                bounds={bounds}
                onChange={(v: string) => setStudentDropoffLocation({ address: v })}
                onSubmit={(v: LocationProps) => setStudentDropoffLocation(v)}
              />
            </Grid>
            {isNeedPMPool && (
              <ModalConfirmRemoveComponent
                data_testid='DeleteAfternoonCarpoolIcon'
                title={`Remove Afternoon Carpool?`}
                body={'You will no longer see afternoon carpool requests'}
                onClick={handleDelSubmit}
              />
            )}
          </Grid>
        </PaperComponent>
        {/* Select seats */}
        <PaperComponent subtitle={'Available Seats'}>
          <SelectComponent
            label='Select seats'
            options={[1, 2, 3, 4, 5, 6, 7]}
            value={dropoffSeatsAvailable ?? ''}
            onChange={(v: number) => setDropoffSeatsAvailable(v)}
          />
        </PaperComponent>
        {/* Select activities */}
        <PaperComponent subtitle={'Activities / Tags'}>
          <TagComponent
            label='Set activities or event tags'
            options={schoolActivities ?? []}
            selected={studentActivities ?? []}
            allowMultiple={true}
            helperText='Contact school admin for missing activities/tags.'
            onChange={(v: string[]) => setStudentActivities(v)}
          />
        </PaperComponent>
        {/* Add comments */}
        <PaperComponent subtitle={'Comments'}>
          <InputComponent
            type='text'
            rows={4}
            label='Comments'
            placeholder='Enter details that might help with carpooling arrangements'
            helperText={`Share details about your vehicle, vanpool interests, schedule, preferences, or needs.`}
            isRequired={false}
            isError={!isValidComments(dropoffComments)}
            value={dropoffComments}
            onChange={(v: string) => setDropoffComments(v)}
          />
        </PaperComponent>
        {/* Update schedule */}
        <PaperComponent subtitle={'Schedule'}>
          <CheckboxComponent
            isChecked={dropoffTimeDefault !== -1 ? true : false}
            color={'warning'}
            label={`Carpool everyday at dismissal`}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setDropoffTimeDefault(event.target.checked ? null : -1)
            }
            justifyContent={'left'}
          />
          <div className='w-full text-sm text-disable p-0 bg-gray-500 flex gap-1 rounded-xl mt-0'>
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
              <path
                d='M9.1665 5.83268H10.8332V7.49935H9.1665V5.83268ZM9.1665 9.16602H10.8332V14.166H9.1665V9.16602ZM9.99984 1.66602C5.39984 1.66602 1.6665 5.39935 1.6665 9.99935C1.6665 14.5993 5.39984 18.3327 9.99984 18.3327C14.5998 18.3327 18.3332 14.5993 18.3332 9.99935C18.3332 5.39935 14.5998 1.66602 9.99984 1.66602ZM9.99984 16.666C6.32484 16.666 3.33317 13.6743 3.33317 9.99935C3.33317 6.32435 6.32484 3.33268 9.99984 3.33268C13.6748 3.33268 16.6665 6.32435 16.6665 9.99935C16.6665 13.6743 13.6748 16.666 9.99984 16.666Z'
                fill='#666666'
              />
            </svg>
            {dropoffTimeDefault !== -1 ? (
              <div className='text-headings font-medium'>
                <>
                  Daily carpool at <span style={{ color: 'green' }}>{getTimeString(schoolEndTime)}</span>
                  {' dismissal. Tap a date to change.'}
                </>
              </div>
            ) : (
              <div className='text-headings font-medium'>Tap a date to schedule carpool days and times.</div>
            )}
          </div>
          <CalendarComponent
            events={events}
            onChange={async (event: CalendarProps) => {
              const d: string = getFullDate(event.from)
              const t: number = event.userAction === CalendarState.DISABLE ? -1 : getTimeNumber(event.from)
              const { msg, error } = await updateStudentAfternoonScheduleSettings(schoolName, studentId, { [d]: t })
              if (error) setMsg(msg)
              else {
                setDropoffDateTime({ ...dropoffDateTime, [d]: t })
                await onUpdate()
              }
            }}
          />
        </PaperComponent>
        <ButtonComponent
          text='Submit'
          width='full'
          disable={!isValid}
          endIcon={<ArrowForwardIcon />}
          onClick={onSubmit}
        />
      </PaperComponent>

      {/***************** Display progress *****************/}
      {isLoading && (
        <Dialog open={true}>
          <DialogTitle>{isLoading}</DialogTitle>
          <CircularProgressWithLabel />
        </Dialog>
      )}

      {/***************** Display messages *****************/}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
    </CenterComponent>
  )
}

interface Props {
  students: StudentProps[]
  processQuery: (id: string) => string
  onUpdate: (cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()

  // Inputs: Selected Student Id, and Callback
  const { students, processQuery, onUpdate } = props

  // We should never call this component without student id query search parameters, but this is just to be safe
  const studentIdFromQuery = searchParams.get('id') ?? ''
  const date = searchParams.get('date')
  useEffect(() => {
    setSearchParams({ id: processQuery(studentIdFromQuery), ...(date ? { date } : {}) })
  }, [studentIdFromQuery, date, processQuery, setSearchParams])

  // Build props for child component
  const student: StudentProps | undefined = students?.find((s: StudentProps) => s.studentId === studentIdFromQuery)

  if (!student) return <LinearProgress />
  return <AfternoonComponent student={student} date={date} onUpdate={onUpdate} />
}

export default Index
