/* eslint @typescript-eslint/no-unused-vars: "off" */
import LightModeIcon from '@mui/icons-material/LightMode'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import PeopleIcon from '@mui/icons-material/People'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import { PieValueType } from '@mui/x-charts'
import PieChartComponent from 'components/piechart'
import ValueChartComponent from 'components/valuechart'
import { SchoolStatProps } from 'utils/types'
import './index.css'

type MetricsComponentProps = {
  schoolStats?: SchoolStatProps
  isHidePickup?: boolean
  isHideDropoff?: boolean
}

const MetricsComponent = (props: MetricsComponentProps) => {
  const { schoolStats, isHidePickup, isHideDropoff } = props ?? {}
  const { totalUsers, pickupUsers, dropoffUsers, inactiveUsers } = schoolStats ?? {}
  const getPieValues = (o?: Record<string, number>): PieValueType[] => {
    return o
      ? Object.keys(o ?? {})
          .map((v: any, id: number) => ({ id, label: v, value: o[v] }))
          .sort((a: any, b: any) => b.value - a.value)
      : []
  }
  const pickupUsersByCity: PieValueType[] = getPieValues(schoolStats?.pickupUsersByCity)
  const dropoffUsersByCity: PieValueType[] = getPieValues(schoolStats?.dropoffUsersByCity)
  const pickupUsersByPostalCode: PieValueType[] = getPieValues(schoolStats?.pickupUsersByPostalCode)
  const dropoffUsersByPostalCode: PieValueType[] = getPieValues(schoolStats?.dropoffUsersByPostalCode)

  return (
    <>
      <Grid container spacing={0}>
        {!isHidePickup && !isHideDropoff && (
          <ValueChartComponent
            title='Total Users'
            data_testid='total-users-testid'
            value={totalUsers}
            avatar={
              <Avatar sx={{ backgroundColor: 'seagreen' }}>
                <PeopleIcon />
              </Avatar>
            }
          />
        )}
        {!isHidePickup && !isHideDropoff && (
          <ValueChartComponent
            title='Inactive Users'
            value={inactiveUsers}
            avatar={
              <Avatar sx={{ backgroundColor: 'firebrick' }}>
                <PeopleIcon />
              </Avatar>
            }
          />
        )}
        {!isHidePickup && (
          <ValueChartComponent
            title='Morning Users'
            value={pickupUsers}
            avatar={
              <Avatar sx={{ backgroundColor: 'cornflowerblue' }}>
                <LightModeOutlinedIcon />
              </Avatar>
            }
          />
        )}
        {!isHideDropoff && (
          <ValueChartComponent
            title='Afternoon Users'
            value={dropoffUsers}
            avatar={
              <Avatar sx={{ backgroundColor: 'darkorange' }}>
                <LightModeIcon />
              </Avatar>
            }
          />
        )}
      </Grid>
      <Grid container spacing={0}>
        {pickupUsersByCity.length > 0 && !isHidePickup && (
          <PieChartComponent
            title={
              <>
                <LightModeOutlinedIcon sx={{ fontSize: 20 }} />
                Morning Users by City
              </>
            }
            data={pickupUsersByCity}
            height={250}
          />
        )}
        {pickupUsersByPostalCode.length > 0 && !isHidePickup && (
          <PieChartComponent
            title={
              <>
                <LightModeOutlinedIcon sx={{ fontSize: 20 }} />
                Morning Users by ZipCode
              </>
            }
            data={pickupUsersByPostalCode}
            height={250}
          />
        )}
        {dropoffUsersByCity.length > 0 && !isHideDropoff && (
          <PieChartComponent
            title={
              <>
                <LightModeIcon sx={{ fontSize: 20 }} />
                Afternoon Users by City
              </>
            }
            data={dropoffUsersByCity}
            height={250}
          />
        )}
        {dropoffUsersByPostalCode.length > 0 && !isHideDropoff && (
          <PieChartComponent
            title={
              <>
                <LightModeIcon sx={{ fontSize: 20 }} />
                Afternoon Users by ZipCode
              </>
            }
            data={dropoffUsersByPostalCode}
            height={250}
          />
        )}
      </Grid>
    </>
  )
}

export default MetricsComponent
